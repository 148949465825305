import React from 'react';
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TablePagination,
} from '@mui/material';
import { useIsMobile } from 'utils/customHooks';

import makeStyles from '@mui/styles/makeStyles';

import TableHeadInvoiceTemplate from './TableHeadInvoiceTemplate';

import clsx from 'clsx';
import { DEFAUL_PAGE_SIZE } from 'components/Shared/constants';

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
  },
  overflow: {
    overflow: 'auto',
  },
  table: {
    width: 'inherit',
  },
  tableBody: {
    backgroundColor: palette.background.paper,
    '& > tr:nth-child(even)': {
      backgroundColor: palette.primary.table,
    },
    '& > tr > td > p': {
      color: palette.text.primary,
    },
  },
}));
const TableListInvoiceTemplate = props => {
  const {
    children,
    headers,
    overflow = false,
    onScroll,
    enablePagination,
    pagination,
    onChangePageSize,
    onNavigate,
    sortParams,
    showCheck,
    onApplySorting,
    onSelectAll,
    selectAll,
    checkIndeterminate,
  } = props;

  const classes = useStyles();
  const isMobile = useIsMobile();

  const showPagination = enablePagination && !isMobile;

  return (
    <Paper
      square
      elevation={0}
      onScroll={onScroll ? onScroll : () => {}}
      className={clsx({
        [classes.root]: true,
        [classes.overflow]: overflow,
      })}
    >
      <MuiTable className={classes.table}>
        {headers && headers.length > 0 && (
          <TableHeadInvoiceTemplate
            sortParams={sortParams}
            onApplySorting={onApplySorting}
            columns={headers}
            showCheck={showCheck}
            onSelectAll={onSelectAll}
            selectAll={selectAll}
            checkIndeterminate={checkIndeterminate}
          />
        )}
        <TableBody className={classes.tableBody}>{children}</TableBody>
      </MuiTable>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={[50, 75, 100]}
          component="div"
          count={pagination?.totalCount || 0}
          rowsPerPage={pagination?.pageSize || DEFAUL_PAGE_SIZE}
          page={pagination?.activePage || 0}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={(event, page) => onNavigate(page)}
          onRowsPerPageChange={event => onChangePageSize(event.target.value)}
        />
      )}
    </Paper>
  );
};
export default TableListInvoiceTemplate;
