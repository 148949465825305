import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { POSSIBLE_MOBILE_SIZES } from 'components/Shared/constants';

export function useDidMount(func) {
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
      func();
      didMountRef.current = false;
    }
  }, [func]);
}

export function useWillUnmount(func) {
  const willUnmountRef = useRef(false);
  useEffect(() => {
    return () => {
      willUnmountRef.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (willUnmountRef.current === true) {
        func();
        willUnmountRef.current = false;
      }
    };
  }, [func]);
}

export function useSingleDoubleClick(singleClick, doubleClick, delay = 200) {
  const timer = useRef(null);
  const func = e => {
    if (timer.current === null) {
      timer.current = setTimeout(() => {
        timer.current = null;
        singleClick(e);
      }, delay);
    } else {
      clearTimeout(timer.current);
      timer.current = null;
      doubleClick(e);
    }
  };

  return func;
}
/**
 * Call setFunc with if ref is intersecting with parentRef
 */
export function useObserveOutside(
  setFunc,
  ref,
  parentRef = null,
  rootMargin = null,
  threshold = 0,
) {
  const callback = useCallback(
    (entries, observer) => {
      const isIntersecting = entries[0].isIntersecting;
      if (isIntersecting) {
        setFunc(true);
      } else {
        setFunc(false);
      }
    },
    [setFunc],
  );

  const observer = useMemo(() => {
    const options = {};
    if (rootMargin) {
      options.rootMargin = rootMargin;
    }
    if (parentRef) {
      options.root = parentRef.current;
    }
    if (threshold) {
      options.threshold = threshold;
    }

    return new IntersectionObserver(callback, options);
  }, [callback, parentRef, rootMargin, threshold]);

  useEffect(() => {
    const refCurrent = ref.current;
    if (refCurrent) {
      observer.observe(refCurrent);
    }
    return () => {
      observer.unobserve(refCurrent);
    };
  }, [observer, ref]);
}

/** Currently unused */
export function useHidePendoPlus() {
  useEffect(() => {
    const pendoClasses = [
      '_pendo-resource-center-badge-container',
      '_pendo-resource-center-badge-image',
    ];
    const interval = setInterval(() => {
      pendoClasses.forEach(className => {
        const pendo = document.getElementsByClassName(className)[0];
        if (pendo && pendo.style.top !== '5000px') {
          pendo.style.top = '5000px';
        }
      });
    }, 200);

    return () => {
      const pendoClasses = [
        '_pendo-resource-center-badge-container',
        '_pendo-resource-center-badge-image',
      ];
      pendoClasses.forEach(className => {
        const pendo = document.getElementsByClassName(className)[0];
        if (pendo && pendo.style.top) {
          pendo.style.top = undefined;
        }
      });
      clearInterval(interval);
    };
  }, []);
}
/**
 *
 * @param {Number} minWidth  starting and min width value
 * @param {Number} minHeight starting and min height value
 * @returns {
 *  isResizing    Bool:   true when currently resizing
 *  width         Number: current width
 *  height        Number: current height
 *  enableResize  func:   Pass to onMouseDown prop on target element
 * }
 * https://stackoverflow.com/a/68742668/901311
 */
export const useResize = ({ minWidth, minHeight }) => {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(minWidth);
  const [height, setHeight] = useState(minHeight);

  //TODO - Add in ability so resizing works only from the initial click
  // currently it will stop once we get to min width/height but then can add more by moving mouse
  // in positive direction.
  // const [initialX, setInitialX] = useState(0);
  // const [initialY, setInitialY] = useState(0);

  const enableResize = useCallback(
    e => {
      // setInitialX(e.clientX);
      // setInitialY(e.clientY);
      setIsResizing(true);
      e.stopPropagation();
    },
    [setIsResizing],
  );

  const disableResize = useCallback(() => {
    setIsResizing(false);
  }, [setIsResizing]);

  const resize = useCallback(
    e => {
      if (isResizing) {
        setWidth(w => {
          if (e.clientX >= window.innerWidth) return w;

          return Math.min(
            window.innerWidth,
            Math.max(minWidth, w + e.movementX),
          );
        });

        setHeight(h => {
          if (e.clientY >= window.innerHeight) return h;
          return Math.min(
            window.innerHeight,
            Math.max(minHeight, h + e.movementY),
          );
        });
      }
    },
    [isResizing, minWidth, minHeight],
  );

  useEffect(() => {
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', disableResize);

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', disableResize);
    };
  }, [disableResize, resize]);

  return { width, height, enableResize, isResizing };
};

/**
 *
 * @param {Date || null} initLastSave
 * @param {func} onSave - return false to NOT set last save time.
 * @param {Number} timeout
 * @returns {
 *  setLastChangeTime   {func} - update time change was made
 *  lastSaveTime        {date}
 *  onAutoSave          {func} - execute save manually
 *  hasUnsavedChanges   {bool}
 * }
 *
 */

export const useAutoSave = (
  onSave,
  initLastSave,
  isPreEditComment,
  saveInterval = 5000,
) => {
  const [lastChangeTime, setLastChangeTime] = useState(null);
  const [lastSaveTime, setLastSaveTime] = useState(initLastSave);

  const onAutoSave = useCallback(() => {
    const success = onSave();
    if (success) setLastSaveTime(new Date());
  }, [onSave]);

  const checkSave = useCallback(() => {
    if (
      lastChangeTime - lastSaveTime > 0 ||
      (lastChangeTime && !lastSaveTime)
    ) {
      onAutoSave();
    }
  }, [lastChangeTime, lastSaveTime, onAutoSave]);

  useEffect(() => {
    if (isPreEditComment) {
      return;
    }
    const intervalId = setInterval(() => {
      checkSave();
    }, saveInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [checkSave, saveInterval, isPreEditComment]);

  const hasUnsavedChanges = lastChangeTime - lastSaveTime > 0;

  return { setLastChangeTime, lastSaveTime, onAutoSave, hasUnsavedChanges };
};

/**
 * Prevents a dynamic height element from shrinking smaller after its grown.
 * @returns ref to be attached to containing element
 */
export const useKeepMinHeight = () => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;

    const styles = window.getComputedStyle(ref.current);

    const heightNum = Number(styles.height.split('px')[0]);
    const minHeightNum = Number(styles['min-height'].split('px')[0]);
    if (
      heightNum > minHeightNum ||
      !styles['min-height'] ||
      Number.isNaN(minHeightNum)
    ) {
      ref.current.style.minHeight = styles.height;
    }
  });

  return ref;
};

// wrapper for useObserveOutside so it can be conditional

export const TargetDetachedObserver = props => {
  const { callback, targetRef, rootMargin } = props;

  const setFunc = useCallback(
    v => {
      if (v === false) {
        callback();
      }
    },
    [callback],
  );

  useObserveOutside(setFunc, targetRef, null, rootMargin);

  return <React.Fragment></React.Fragment>;
};

TargetDetachedObserver.propTypes = {
  callback: PropTypes.func.isRequired,
  targetRef: PropTypes.object.isRequired,
  rootMargin: PropTypes.string,
};

export const useBroadcastChannel = channelName => {
  const [channel, setChannel] = useState();

  useEffect(() => {
    const newBc = new BroadcastChannel(channelName);
    setChannel(newBc);
    return () => {
      newBc.close();
    };
  }, [channelName]);

  return channel;
};

export const useIsMobile = (size = 'md') => {
  if (!POSSIBLE_MOBILE_SIZES.includes(size)) {
    throw new Error(
      `withIsMobile: size must be one of ${POSSIBLE_MOBILE_SIZES.join(', ')}`,
    );
  }
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(size));
};

//https://v5.reactrouter.com/web/example/query-parameters
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
