import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import { getProject as project } from 'selectors/routeParams';
import { push } from 'redux-first-history';
import { showAlert } from 'actions/alert';
import * as actions from 'actions/userInfo';
import { setupPendoInfo, delayOnValue } from 'utils/helperFunctions';
import { ROLE_LABELS, IA, PROJECT_ADMIN } from 'components/props/profiles';
import { addReadableRole } from 'feature/CrewList/CrewListUtils';

export function* fetchUserInfo(api, projectId) {
  yield put(actions.loading({ loading: true }));
  const activeUser = yield call(api.projects.userInfo, { projectId });
  if (activeUser) {
    addUserInfoToPendo(activeUser);

    yield put(actions.store({ projectId, activeUser }));
  }
  yield put(actions.loading({ loading: false }));
}

function addUserInfoToPendo(activeUser) {
  const dtoUser = {
    ...activeUser,
    worksightId: activeUser.workSightId,
    roles: activeUser.projectRoles,
  };
  const { readableRole } = addReadableRole(dtoUser);

  let role = readableRole;
  if (activeUser.projectRoles.includes(IA) && role !== ROLE_LABELS[IA]) {
    role += ` | ${ROLE_LABELS[IA]}`;
  }

  if (activeUser.projectRoles.includes(PROJECT_ADMIN)) {
    role += ` | ${ROLE_LABELS[PROJECT_ADMIN]}`;
  }

  //storing User data in session for pendo
  const pendoUser = {
    role: role,
    email: activeUser?.email || '',
  };
  sessionStorage.setItem('pendo_profile', JSON.stringify(pendoUser));
  //setting up pendo parameters
  setupPendoInfo();
}

function* fetch(api, debug) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield delayOnValue(project);
    if (projectId) {
      yield call(fetchUserInfo, api, projectId);
    }

    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.store({ settings: [] }));
    yield put(actions.loading({ loading: false }));
    yield put(showAlert());
    yield put(push('/projects'));
  }
}

function* fetchPermissions(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const projectId = yield delayOnValue(project);
    const permissions = yield call(api.projects.getPermissions, { projectId });
    yield put(actions.storePermissions({ projectId, permissions }));
    yield put(actions.loading({ loading: false }));
  } catch (error) {
    debug(error);
  }
}

function* fetchWorksightId(api, debug, params) {
  try {
    const userId = params.userId;
    const data = yield call(api.users.worksightId, { userId });
    const worksightId = data.worksightId;
    yield put(actions.storeWorksightId({ worksightId }));
  } catch (e) {
    debug(e);
  }
}

export default function* userInfoFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetch}`, fetch, api, debug),
    takeEvery(`${actions.fetchPermissions}`, fetchPermissions, api, debug),
    takeLatest(`${actions.fetchWorksightId}`, fetchWorksightId, api, debug),
  ]);
}
