import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyleSheet } from 'shared/theme';
import { CardMedia as MuiCardHeader, Divider, Grid } from '@mui/material';

const style = theme => ({
  root: {
    borderBottom: [1, 'solid', theme.palette.gray['+8']],
    fontFamily: 'inherit',
  },

  paddedTitle: {
    padding: 16,
  },

  unPaddedTitle: {
    padding: 14,
  },

  withButtons: {
    padding: {
      top: 7,
      bottom: 7,
      left: 17,
      right: 17,
    },
  },

  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
  },

  brand: {
    color: 'white',
    borderBottom: 0,
    backgroundImage: theme.palette.brand,
  },

  titleBar: {
    fontFamily: 'inherit',
    fontSize: 18,
    '& > *': {
      fontFamily: 'inherit',
      fontSize: 18,
    },
  },

  navSection: {
    textAlign: 'right',
  },
});

const CardHeader = ({
  buttons,
  classes,
  children,
  title = false,
  image = ' ',
}) => {
  const hasButtons = buttons;

  const rootClass = clsx(classes.root, {
    [classes.withButtons]: hasButtons,
    [classes.paddedTitle]: !hasButtons,
    [classes.unPaddedTitle]: true,
    [classes.brand]: !title,
  });

  return [
    <MuiCardHeader className={rootClass} key={`Card-Title`} image={image}>
      <Grid
        container
        spacing={8}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Grid item className={title ? classes.titleBar : null}>
          {children}
        </Grid>
        {hasButtons && (
          <Grid item className={classes.navSection}>
            {buttons}
          </Grid>
        )}
      </Grid>
    </MuiCardHeader>,
    <Divider key={`Dialog-devider`} />,
  ];
};

CardHeader.propTypes = {
  buttons: PropTypes.node,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  title: PropTypes.bool,
  image: PropTypes.string,
};

export default withStyleSheet('CardHeader', style)(CardHeader);
