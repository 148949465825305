import React from 'react';

import { Header, FullWidthLayout, ProjectHeader } from 'containers/Layout';

//sub nav

import { CrewTimecardList as CrewTimecardSubNav } from 'containers/Nav/Pages';
import { CrewTimecardWeek } from 'containers/Nav/Pages';
import CrewTimecard from 'containers/Employees/CrewTimecard/TeamTimecard';
//components
const CrewTimecardList = React.lazy(() =>
  import('containers/Employees/CrewTimecard/List'),
);
const CreateCrewTimecard = React.lazy(() =>
  import('containers/Employees/CrewTimecard/Create'),
);
// const CrewTimecard = React.lazy(() =>
//   import('containers/Employees/CrewTimecard/TeamTimecard'),  // loading just this via url, is not completing loading
// );

export const CREW_CREATE_PATH =
  '/projects/:projectId/timecards/week-ending/:weekEndingdate/department/:departmentId';
export const CREW_TIMECARD_PATH =
  '/projects/:projectId/timecards/week-ending/:weekEnding/department/:departmentId/team/:id';

const projectCrewTimecardRoutes = [
  {
    title: 'Crew Timecard List',
    path: '/projects/:projectId/timecards',
    headers: [Header, ProjectHeader, CrewTimecardSubNav],
    layout: FullWidthLayout,
    component: CrewTimecardList,
  },
  {
    title: 'Create Crew Timecard',
    path: CREW_CREATE_PATH,
    headers: [Header, ProjectHeader, CrewTimecardWeek],
    layout: FullWidthLayout,
    component: CreateCrewTimecard,
  },
  {
    title: 'Crew Timecard',
    path: CREW_TIMECARD_PATH,
    headers: [Header, ProjectHeader, CrewTimecardWeek],
    layout: FullWidthLayout,
    component: CrewTimecard,
  },
];

export default projectCrewTimecardRoutes;
