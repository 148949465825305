import { Tooltip } from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import makeStyles from '@mui/styles/makeStyles';

import Label from '../../Shared/Text/Label';

import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useIsMobile } from 'utils/customHooks';

const useStyles = makeStyles(({ palette }) => ({
  dotStyle: {
    color: palette.primary.main,
    backgroundColor: palette.primary.background,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: palette.primary['+8'],
    },
  },
}));

//exported to be used in searchTimecards
export const OtherApprovers = ({ list }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const isMobile = useIsMobile();
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = e => {
    e.stopPropagation();
    setOpen(true);
    return false;
  };

  if (list.length > 1) {
    const restApprovers = list.slice(1);
    const tooltip = (
      <span>
        {restApprovers.map((item, i) => {
          const approver =
            typeof item === 'string'
              ? item
              : `${item.lastName}, ${item.firstName}`;
          return (
            <span key={approver} style={{ fontSize: 12 }}>
              {approver}
              <br />
            </span>
          );
        })}
      </span>
    );
    return (
      <span>
        {isMobile && (
          <React.Fragment>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <span>
                <Tooltip
                  placement="right"
                  onClose={handleTooltipClose}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={tooltip}
                  open={open}
                >
                  <MoreHorizIcon
                    onClick={handleTooltipOpen}
                    className={classes.dotStyle}
                  />
                </Tooltip>
              </span>
            </ClickAwayListener>
          </React.Fragment>
        )}
        {!isMobile && (
          <React.Fragment>
            <Tooltip
              placement="right"
              disableFocusListener
              disableTouchListener
              title={tooltip}
            >
              <MoreHorizIcon className={classes.dotStyle} />
            </Tooltip>
          </React.Fragment>
        )}
      </span>
    );
  }
  return null;
};

export const getApproverList = currentApprovers => {
  currentApprovers = currentApprovers ? currentApprovers : [];

  const FirstApprover = () => {
    if (currentApprovers.length > 0) {
      const first = currentApprovers[0];
      const approver =
        typeof first === 'string'
          ? first
          : `${first.lastName}, ${first.firstName}`;

      return (
        <Label uppercase primary>
          {approver}
        </Label>
      );
    }
    return <span />;
  };

  return (
    <span>
      <FirstApprover />
      <OtherApprovers list={currentApprovers} />
    </span>
  );
};
