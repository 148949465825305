// Planning to use it with both admin as well as normal user.
import createAction from './createAction';

export const store = createAction('searchTimecards/store');
export const storeSort = createAction('searchTimecards/storeSort');

export const loading = createAction('searchTimecards/loading');

export const loadMore = createAction('searchTimecards/loadMore');
export const cleanup = createAction('searchTimecards/cleanup');
export const reset = createAction('searchTimecards/reset');
export const init = createAction('searchTimecards/init');

//filterDropdown actions
export const fetch = createAction('searchTimecards/fetch');
export const clearFilters = createAction('searchTimecards/clearFilters');
export const setExpandFilters = createAction(
  'searchTimecards/setExpandFilters',
);

export const setInitialFilters = createAction(
  'searchTimecards/setInitialFilters',
);

export const setSearchFilter = createAction('searchTimecards/setSearchFilter');

export const onSelect = createAction('searchTimecards/onSelect');
export const onSelectAll = createAction('searchTimecards/onSelectAll');

export const storeFilterOptions = createAction(
  'searchTimecards/storeFilterOptions',
);
export const setRemovedSelectedOption = createAction(
  'searchTimecards/setRemovedSelectedOption',
);
// Set filters before navigating to search timecards from external page
export const setInvoiceFilter = createAction(
  'searchTimecards/setInvoiceFilters',
);

export const setDraftFilters = createAction('searchTimecards/setDraftFilters');

export const submitDraftTimecards = createAction(
  'searchTimecards/submitDraftTimecards',
);
export const submittingTimecards = createAction(
  'searchTimecards/submittingTimecards',
);

export const deleteTimecards = createAction('searchTimecards/deleteTimecards');

export const setSelectAllFlag = createAction(
  'searchTimecards/setSelectAllFlag',
);

export const bulkSubmitFromTimecard = createAction(
  'searchTimecards/bulkSubmission',
);

export const setSelectedFlows = createAction(
  'searchTimecards/setSelectedFlows',
);

export const deletingTimecards = createAction(
  'searchTimecards/deletingTimecards',
);
export const storeJobInfo = createAction('searchTimecards/storeJobId');
export const deleteJobInfo = createAction('searchTimecards/deleteJobId');

export const openTimecardsInWTC = createAction('searchTimecards/openInWTC');
