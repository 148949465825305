import {
  all,
  takeEvery,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import camelCase from 'camelcase-keys';
import { startSubmit, stopSubmit, reset, change } from 'redux-form';
import { cloneDeep } from 'lodash';
import { matchPath } from 'react-router-dom';
import {
  CREW_CREATE_PATH,
  CREW_TIMECARD_PATH,
} from 'components/Routes/projectCrewTimecardRoutes';
// selectors
import { getProject as project } from 'selectors/routeParams';
import {
  getTeamTimecardFormValues,
  getTeamAllowances,
  getGroupRounding,
  getCurrentCrewUsers,
} from 'selectors/teamTimecard';
import { getWorkLocations } from 'selectors/timecard/common';
import { getProjectDetails, getDepartments } from 'selectors/project';

// actions
import * as actions from 'actions/timecards';
import { showAlert } from 'actions/alert';
import { hide as hideModal } from 'actions/modalDialog';
import { initialize as enablePagination } from 'actions/pagination';
import { storeProjectUsers } from 'actions/project';

//util
import {
  findCommonsInMultipleArray,
  removeNoValueFromTimecard,
  composeTeamTimecard,
  parseSelectedTTUser,
  setWorkLocationForCanada,
} from 'utils/helperFunctions';
import { composeAllowanceV1 } from 'utils/weekUtils';
// other actions.
import { fetchEpisodes, convertTimesToMil } from './timecards';
import { ERROR_MESSAGES } from 'components/Shared/constants/ErrorMessages';
import { getTeamTimecard } from 'selectors/teamTimecard';
import { getSettings } from 'selectors/settings';

const FORM_NAME = 'teamTimecard';

//called in groupTimecard modal
export function* createTeamTimecard(api, debug, params) {
  const { departmentId, selectedUsers, projectId, weekEndingdate } = params;
  try {
    yield put(actions.loading({ loading: true }));

    const crewUsers = yield select(getCurrentCrewUsers);

    const batchTemplate = yield getBatchTemplateForDept(api, {
      departmentId,
    });
    const roundTo = yield getTeamRounding(api, debug, selectedUsers);
    const departments = yield select(getDepartments) || [];
    const dept = departments.find(d => d.id === Number(departmentId));
    const settings = yield select(getSettings);
    let wtcLayoutName =
      dept?.wtcLayoutName || settings?.wtcLayout?.label || null;
    const data = {
      projectId,
      departmentId,
      weekEndingdate,
      crewUsers,
      selectedUsers,
      batchTemplate,
      roundTo,
      wtcLayoutName,
    };

    const teamTimecard = composeTeamTimecard(data);
    yield put(actions.storeTeamTimecard({ teamTimecard }));

    yield put(hideModal({ dialog: 'groupTimecard' }));
    const toURL = `/projects/${projectId}/timecards/week-ending/${weekEndingdate}/department/${departmentId}/team/fresh-0`;
    yield put(push(toURL));
  } catch (e) {
    debug(e);

    yield put(showAlert());
  } finally {
    yield put(actions.loading({ loading: false }));
  }
}

export function* getBatchTemplateForDept(api, params) {
  const { departmentId } = params;
  const projectId = yield select(project);

  const data = yield call(api.invoiceTemplate.templates, { projectId });
  const templates = camelCase(data, { deep: true });
  const result = templates.find(t =>
    t.departments.includes(Number(departmentId)),
  );
  return result;
}

export function* fetchTeamTimecard(api, debug) {
  try {
    yield put(actions.loadingTeamTimecard({ loading: true }));

    const timecard = yield select(getTeamTimecard);
    const users = timecard.users;

    yield all([
      call(fetchEpisodes, api, debug),
      call(fetchSoloOptions, api, debug, { users }),
    ]);
  } catch (e) {
    debug(e);
  } finally {
    yield put(actions.loadingTeamTimecard({ loading: false }));
  }
}

function* getTeamRounding(api, debug, selectedUsers) {
  const { dealMemoId } = parseSelectedTTUser(selectedUsers[0]);

  try {
    let groupRounding = yield select(getGroupRounding);

    //If we still have the data from the create check, use that
    if (groupRounding.length > 0) {
      const result = groupRounding.find(gr => gr.id === dealMemoId);
      if (result?.roundTo) {
        return result.roundTo;
      }
    }

    //else fetch it fresh
    const projectId = yield select(project);
    groupRounding = yield call(api.employees.roundTo, {
      projectId,
      dealMemoIds: [dealMemoId],
    });

    return groupRounding[0].roundTo;
  } catch (error) {
    debug(error);
  }
}

function* fetchSoloOptions(api, debug, params) {
  const users = params.users || [];

  yield all([
    put(actions.fetchTeamWorkLocations({ users })),
    put(actions.fetchTeamDayTypes({ users })),
  ]);
}

export function* submitTeamTimecard(api, debug, params) {
  try {
    yield put(actions.savingTimecard({ savingTimecard: true }));
    yield put(startSubmit(FORM_NAME));

    const projectId = yield select(project);
    const { weekEnding, departmentId } = params;
    let timecard = yield select(getTeamTimecardFormValues);
    timecard = cloneDeep(timecard);

    convertTimesToMil(timecard, { convert24Plus: true });
    const projectDetails = yield select(getProjectDetails);
    const isCaRegion = projectDetails.region === 'Canada';
    if (isCaRegion) {
      const workLocations = yield select(getWorkLocations);
      timecard = setWorkLocationForCanada(timecard, workLocations);
    }

    removeNoValueFromTimecard(timecard);

    timecard.submitRequest = {};
    if (params.emergencyType) {
      timecard.submitRequest = {
        comment: params.notes,
        emergencyType: params.emergencyType,
      };
    }

    delete timecard.mealLabel;
    delete timecard.editableFields;

    removeNoValueFromTimecard(timecard);

    const data = new FormData();

    let count = 0;
    timecard?.allowances?.forEach(allowance => {
      if (allowance.document) {
        const key = `document${count}`;
        data.append(key, allowance.document);
        allowance.document = key;
        count++;
      }
    });

    for (const key in timecard) {
      if (Object.hasOwnProperty.call(timecard, key)) {
        const element = timecard[key];
        data.append(key, JSON.stringify(element));
      }
    }

    yield call(api.timecards.submitToEmployees, {
      projectId,
      weekEnding,
      data,
    });

    yield put(reset(FORM_NAME));
    yield put(stopSubmit(FORM_NAME));
    // redirect.
    yield put(actions.storeTeamTimecard({ teamTimecard: null }));
    yield put(
      push(
        `/projects/${projectId}/timecards/week-ending/${weekEnding}/department/${departmentId}`,
      ),
    );
    yield put(actions.savingTimecard({ savingTimecard: false }));
  } catch (e) {
    const errors = e.data ? camelCase(e.data, { deep: true }) : null;
    yield put(actions.savingTimecard({ savingTimecard: false }));
    if (errors && errors.timecard) {
      debug(e);
      yield put(
        showAlert({
          message: ERROR_MESSAGES[errors.timecard],
          variant: 'error',
        }),
      );
    } else if (errors && errors.days) {
      yield put(
        showAlert({
          message: 'Timecard validation failed.',
          variant: 'warning',
        }),
      );
    } else {
      yield put(showAlert());
      debug(e);
    }
    yield put(stopSubmit(FORM_NAME, { _error: { ...errors } }));
  }
}

export function* removeEmployee(api, debug, params) {
  try {
    const { userId } = params;

    const tc = yield select(getTeamTimecard);
    const users = userId ? tc && tc.users.filter(u => u.userId !== userId) : [];

    yield put(actions.removeLocalEmployee({ userId }));

    yield fetchSoloOptions(api, debug, { users });
  } catch (e) {
    debug(e);
  }
}

export function* saveTeamAllowance(api, debug, params) {
  try {
    yield put(actions.processingAllowances({ loading: true }));
    let { allowance, index } = params;

    let data = {};

    const allowance_type = {
      value: allowance.isDefaultAllowance
        ? allowance.defaultAllowanceId
        : allowance.htgAllowanceTypeId,
      isDefaultAllowance: allowance.isDefaultAllowance || false,
    };

    const allowances = yield select(getTeamAllowances, FORM_NAME);

    if (index === undefined) index = allowances.length;

    data = {
      amount: allowance.amount,
      document: allowance.document || null,
      filename: allowance.filename,
      htg_allowance_type_id: allowance.htgAllowanceTypeId,
      isDefaultAllowance: allowance.isDefaultAllowance || false,
      allowance_type,
    };

    if (allowance.isDefaultAllowance) {
      data.default_allowance_id = allowance.defaultAllowanceId;
    }

    data = composeAllowanceV1(allowance);

    delete data.reimbursementType;

    yield put(change(FORM_NAME, `allowances[${index}]`, data));

    yield put(actions.resetAllowanceInput());
    yield put(actions.processingAllowances({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.resetAllowanceInput());
    yield put(actions.processingAllowances({ loading: false }));
    yield put(showAlert());
  }
}

export function* deleteTeamAllowances(api, debug, params) {
  try {
    yield put(actions.processingAllowances({ loading: true }));
    const { index } = params;

    const currAllowances = yield select(getTeamAllowances, FORM_NAME);
    const allowances = currAllowances.slice();
    allowances.splice(index, 1);

    yield put(change(FORM_NAME, `allowances`, allowances));

    yield put(hideModal({ dialog: 'DeleteTimecardAllowance' }));
    yield put(actions.processingAllowances({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(hideModal({ dialog: 'DeleteTimecardAllowance' }));
    yield put(actions.processingAllowances({ loading: false }));
  }
}

export function* fetchTeamWorkLocations(api, debug, params) {
  try {
    const { users } = params;

    const paramsList = [];
    users.forEach(user => {
      const {
        dealMemo: { htgUnion, htgContract },
      } = user;

      const htgUnionId = htgUnion && htgUnion.id;
      const htgContractId = htgContract && htgContract.id;

      const result = paramsList.find(p => p.htgUnionId === htgUnionId);

      if (!result) {
        paramsList.push({ htgUnionId, htgContractId });
      } else {
        if (result.htgContractId !== htgContractId) {
          paramsList.push({ htgUnionId, htgContractId });
        }
      }
    });
    const type = 'locationType';

    const workLocationsLists = yield all([
      ...paramsList.map(param =>
        call(api.timecards.searchByTypes, {
          type,
          params: { options: param },
        }),
      ),
    ]);

    const teamWorkLocations = yield findCommonsInMultipleArray(
      workLocationsLists,
    );

    const workLocations = camelCase(teamWorkLocations, { deep: true });
    yield put(actions.storeWorkLocations({ workLocations }));
  } catch (e) {
    debug(e);
  }
}

export function* fetchWeekendingStats(api, debug, params) {
  try {
    yield put(actions.loading({ loading: true }));
    const prtojectDetails = yield select(getProjectDetails);
    const { departmentId } = params;
    const { worksightId, id, dbCode } = prtojectDetails;
    const payload = {
      filters: [
        { field: 'project.id', type: 'key', values: [`${worksightId}`] },
        { field: 'department.code', type: 'key', values: [`${departmentId}`] },
        { field: 'batch.dbCode', type: 'key', values: [`${dbCode}`] },
      ],
    };
    const data = yield call(api.timecards.weekendingsStats, id, payload);
    const weekendingStats = camelCase(data, { deep: true });

    const batchTemplate = yield getBatchTemplateForDept(api, {
      departmentId,
    });

    if (!batchTemplate) {
      throw new Error('Department missing batch template', {
        cause: 'missingBatchTemplate',
      });
    }

    yield put(actions.storeWeekendingStats({ weekendingStats }));
    yield put(
      enablePagination({
        grid: 'crew-timecard',
        totalCount: weekendingStats.length,
      }),
    );
    yield put(actions.loading({ loading: false }));
  } catch (e) {
    debug(e);
    yield put(actions.storeWeekendingStats({ weekendingStats: [] }));
    yield put(actions.loading({ loading: false }));
    if (e.cause === 'missingBatchTemplate') {
      yield put(
        showAlert({
          message:
            'This department has not been assigned to a batch template. Contact plus support for assistance',
        }),
      );
    } else {
      yield put(showAlert());
    }
  }
}

export function* fetchTeamDayTypes(api, debug, params) {
  try {
    const { users } = params;
    const parentValues = [];
    users.forEach(user => {
      const {
        dealMemo: { htgContract },
      } = user;

      const htgContractId = htgContract?.id;
      if (!htgContractId) return;

      if (parentValues.includes(htgContractId) === false) {
        parentValues.push(htgContractId);
      }
    });

    const type = 'dayType';
    const dayTypesList = yield all([
      ...parentValues.map(parentValue =>
        call(api.timecards.searchByTypes, {
          type,
          params: { parentValue, pageSize: -1 },
        }),
      ),
    ]);

    const teamDayTypes = yield findCommonsInMultipleArray(dayTypesList);

    const dayTypes = camelCase(teamDayTypes, { deep: true });
    yield put(actions.storeDayTypes({ dayTypes }));
  } catch (e) {
    debug(e);
  }
}

function* fetchGroupRounding(api, debug, params) {
  try {
    const { dealMemoIds } = params;
    const projectId = yield select(project);

    const groupRounding = yield call(api.employees.roundTo, {
      projectId,
      dealMemoIds,
    });

    yield put(actions.storeGroupRounding({ groupRounding }));
  } catch (error) {
    debug(error);
  }
}

export function* teamTimecardUnmountCheck(api, debug, params) {
  try {
    const location = window.location;
    const isCrewTimecard = matchPath(location.pathname, {
      path: CREW_TIMECARD_PATH,
      exact: true,
    });
    const isCrewCreate = matchPath(location.pathname, {
      path: CREW_CREATE_PATH,
      exact: true,
    });

    if (isCrewCreate || isCrewTimecard) {
      //do nothing - we're still in crew
    } else {
      yield put(actions.storeWeekendingTimecards({ weekendingTimecards: [] }));
      yield put(storeProjectUsers({ users: [] }));
    }
  } catch (e) {
    debug(e);
  } finally {
  }
}

export default function* teamTimecardFlow({ api, debug }) {
  yield all([
    takeEvery(`${actions.fetchTeamTimecard}`, fetchTeamTimecard, api, debug),
    takeEvery(`${actions.submitTeamTimecard}`, submitTeamTimecard, api, debug),
    takeEvery(`${actions.saveTeamAllowance}`, saveTeamAllowance, api, debug),
    takeEvery(`${actions.removeEmployee}`, removeEmployee, api, debug),
    takeLatest(
      `${actions.fetchTeamWorkLocations}`,
      fetchTeamWorkLocations,
      api,
      debug,
    ),
    takeEvery(
      `${actions.fetchWeekendingStats}`,
      fetchWeekendingStats,
      api,
      debug,
    ),
    takeLatest(`${actions.fetchTeamDayTypes}`, fetchTeamDayTypes, api, debug),
    takeEvery(
      `${actions.deleteTeamAllowances}`,
      deleteTeamAllowances,
      api,
      debug,
    ),
    takeEvery(`${actions.fetchGroupRounding}`, fetchGroupRounding, api, debug),
    takeEvery(`${actions.createTeamTimecard}`, createTeamTimecard, api, debug),
    takeEvery(
      `${actions.teamTimecardUnmountCheck}`,
      teamTimecardUnmountCheck,
      api,
      debug,
    ),
  ]);
}
