import React from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useIsMobile } from 'utils/customHooks';
import withStyles from '@mui/styles/withStyles';
import {
  FaRegCalendarCheck as ReadyIcon,
  FaHistory as HistoryIcon,
  FaHourglassHalf as PendingIcon,
} from 'react-icons/fa';

const style = ({ palette, breakpoints }) => ({
  buttonDashboardTab: {
    display: 'block',
    backgroundImage: palette.dashboard,
    color: palette.common.white,
    cursor: 'pointer',
    height: 'auto',
    width: '100%',
    position: 'relative',
    textTransform: 'none',
    borderRadius: 12,
    opacity: 0.6,
    '&:hover': {
      backgroundColor: palette.secondary.main,
      backgroundImage: 'none',
    },
    [breakpoints.up('md')]: {
      paddingRight: 20,
    },
    [breakpoints.down('lg')]: {
      padding: 10,
      paddingRight: 10,
    },
  },
  buttonDashboardTabArrow: {
    opacity: 1,
    '&:after': {
      top: '100%',
      left: '50%',
      border: 'solid transparent',
      content: "''",
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderTopColor: palette.secondary.main,
      borderWidth: 20,
      marginLeft: -20,
    },
  },
  icon: {
    fontSize: '4.8em',
    color: palette.primary['-3'],
    padding: 16,
    paddingTop: 18,
    paddingRight: 0,
  },
  smallRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  smallText: {
    textAlign: 'center',
  },
  countFont: {
    fontSize: '4em',
  },

  noWrap: {
    flexWrap: 'noWrap',
  },
});

const ReviewsHeaderTab = ({ classes, label, count, icon, linkTo, loading }) => {
  const isMobile = useIsMobile();

  if (loading && !count) {
    //Initial Load
    count = '--';
  } else if (loading) {
    //secondary load
    count = (
      <span>
        <CircularProgress color="secondary" /> {count}
      </span>
    );
  }
  return (
    <NavLink
      to={linkTo}
      activeClassName={classes.buttonDashboardTabArrow}
      className={classes.buttonDashboardTab}
    >
      {!isMobile && (
        <React.Fragment>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
            className={classes.noWrap}
          >
            <Grid item>
              {icon === 'ReadyIcon' && <ReadyIcon className={classes.icon} />}
              {icon === 'HistoryIcon' && (
                <HistoryIcon className={classes.icon} />
              )}
              {icon === 'PendingIcon' && (
                <PendingIcon className={classes.icon} />
              )}
            </Grid>
            <Grid item xs>
              <Typography color="inherit" variant="h4">
                {label}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color="inherit"
                variant="h1"
                className={classes.countFont}
              >
                {count}
              </Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      {isMobile && (
        <React.Fragment>
          <div className={classes.smallRoot}>
            <Typography color="inherit" className={classes.smallText}>
              {label}
            </Typography>
            <Typography color="inherit" variant="h2">
              {count}
            </Typography>
          </div>
        </React.Fragment>
      )}
    </NavLink>
  );
};

export default withStyles(style)(ReviewsHeaderTab);
