import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import withIsMobile from 'decorators/withIsMobile';
import { compose } from 'utils/helperFunctions';
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TablePagination,
} from '@mui/material';
import clsx from 'clsx';
import { paginationProps } from './Pagination';
import TableHead, { columnHeaderProps } from './TableHead';
import { sortProps } from './TableCellSortable';
import { DEFAUL_PAGE_SIZE } from 'components/Shared/constants';

const style = ({ palette }) => ({
  root: {
    width: '100%',
  },
  overflow: {
    overflow: 'auto',
  },
  table: {
    width: 'inherit',
  },
  blankSlate: {},
  tableBody: {
    backgroundColor: palette.primary.table,
    '& > tr:nth-child(even)': {
      backgroundColor: palette.background.paper,
    },
  },
});
class TableList extends Component {
  static propTypes = {
    enablePagination: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    headers: columnHeaderProps.isRequired,
    onApplySorting: PropTypes.func,
    onChangePageSize: PropTypes.func,
    onNavigate: PropTypes.func,
    pagination: paginationProps,
    sortParams: sortProps,
    evenOddRows: PropTypes.bool,
    showCheck: PropTypes.bool,
    checkIndeterminate: PropTypes.bool,
    onSelectAll: PropTypes.func,
    isMobile: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    enablePagination: false,
    onApplySorting: undefined,
    onChangePageSize: undefined,
    onSelectAll: undefined,
    onNavigate: undefined,
    pagination: undefined,
    sortParams: undefined,
    evenOddRows: true,
  };

  renderTableHeader() {
    const {
      sortParams,
      headers,
      showCheck,
      onApplySorting,
      onSelectAll,
      selectAll,
      checkIndeterminate,
    } = this.props;

    return (
      <TableHead
        sortParams={sortParams}
        onApplySorting={onApplySorting}
        columns={headers}
        showCheck={showCheck}
        onSelectAll={onSelectAll}
        selectAll={selectAll}
        checkIndeterminate={checkIndeterminate}
      />
    );
  }
  // For now we only have pagination, if required we can extend this.
  // Hiding in small screens for now (if needed, update UI for small screens)
  renderFooter() {
    const {
      enablePagination,
      pagination,
      onChangePageSize,
      onNavigate,
      isMobile,
    } = this.props;

    if (!enablePagination || isMobile) {
      return null;
    }

    return (
      <TablePagination
        rowsPerPageOptions={[50, 75, 100]}
        component="div"
        count={pagination.totalCount || 0}
        rowsPerPage={pagination.pageSize || DEFAUL_PAGE_SIZE}
        page={pagination.activePage || 0}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={(event, page) => onNavigate(page)}
        onRowsPerPageChange={event => onChangePageSize(event.target.value)}
      />
    );
  }

  render() {
    const {
      classes,
      children,
      headers,
      isAlternateRowColor = true,
      overflow = false,
      onScroll,
      className,
    } = this.props;

    const alternateClass = clsx({
      [classes.tableBody]: isAlternateRowColor,
    });

    const root = clsx({
      [classes.root]: true,
      [classes.overflow]: overflow,
      [className]: !!className,
    });

    return (
      <Paper
        square
        elevation={0}
        onScroll={onScroll ? onScroll : () => {}}
        className={root}
      >
        <MuiTable className={classes.table}>
          {headers && headers.length > 0 && this.renderTableHeader()}
          <TableBody className={alternateClass}>{children}</TableBody>
        </MuiTable>
        {this.renderFooter()}
      </Paper>
    );
  }
}

export default compose(
  withStyleSheet('TableList', style),
  withIsMobile(),
)(TableList);
