import React from 'react';
import PropTypes from 'prop-types';
import { withStyleSheet } from 'shared/theme';
import { CardActions } from '@mui/material';

const style = ({ palette }) => ({
  root: {
    paddingTop: 8, //[8, 16],
    paddingBottom: 8, //[8, 16],
    paddingLeft: 16, //[8, 16],
    paddingRight: 16, //[8, 16],
  },
});

const CardFooter = ({ classes, children, ...props }) => (
  <CardActions className={classes.root} {...props}>
    {children}
  </CardActions>
);

CardFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default withStyleSheet('CardFooter', style)(CardFooter);
