import React from 'react';
import {
  FaRegCheckSquare as CheckIcon,
  FaRegSquare as SquareIcon,
} from 'react-icons/fa';
import { TableCell, TableRow, Typography, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
//components
import { TableList } from 'components/Shared/TableList';
import FringeTip from 'components/Shared/FringeTooltip/FringeTip';

//utils
import { formatDate } from 'utils';
import { twoDecimalsNoRounding } from 'utils/weekUtils';
import { calculateDayHours } from 'utils/weekUtils';
import { isRegionCanada } from 'utils/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '90vw',
    paddingTop: 20,
    whiteSpace: 'nowrap',
    overflowY: 'scroll',
  },
  tableCell: {
    textAlign: 'center',
  },
}));

const hasPaidHours = day => day.id && day.paidHours && day.paidHours.length > 0;

function getPaidHourHeaders(timecard) {
  const days =
    timecard &&
    Array.isArray(timecard.days) &&
    timecard.days.filter(hasPaidHours);

  if (!days || days.length === 0) {
    return [];
  }

  //remove possible headers that have no data for any day of the week
  const headersWithData = new Set();
  days.forEach(day => {
    day.paidHours.forEach(paidHour => {
      if (paidHour.value) {
        headersWithData.add(paidHour.name);
      }
    });
  });

  const filtered = days[0].paidHours.filter(ph => headersWithData.has(ph.name));

  return filtered.map(paidHour => {
    const name = paidHour.name;

    const label =
      name === 'units'
        ? 'MPV AM'
        : name === 'amount'
        ? 'MP Amt AM'
        : name === 'units_pm'
        ? 'MPV PM'
        : name === 'amount_pm'
        ? 'MP Amt PM'
        : name;

    return {
      columnId: name,
      label,
      compact: false,
      sortable: false,
      paidHours: true,
      align: 'center',
    };
  });
}

export default function TimecardDetail({ timecard, dayTypes }) {
  const classes = useStyles();

  const showFringeInfo = timecard?.dealMemo?.guarantees?.some(
    g => g?.grossIncludesVACFGN === true,
  );

  const payCodeFF1 = !!timecard.brokenDetailsFreeField1Count
    ? timecard.brokenDetailsFreeField1Count
    : 0;
  const payCodeFF2 = !!timecard.brokenDetailsFreeField2Count
    ? timecard.brokenDetailsFreeField2Count
    : 0;
  const payCodeFF3 = !!timecard.brokenDetailsFreeField3Count
    ? timecard.brokenDetailsFreeField3Count
    : 0;
  const payCodeFF4 = !!timecard.brokenDetailsFreeField4Count
    ? timecard.brokenDetailsFreeField4Count
    : 0;
  const countryCount = !!timecard.brokenDetailsCountryCount
    ? timecard.brokenDetailsCountryCount
    : 0;

  function renderDays() {
    const meals = timecard.meals ? timecard.meals : 0;
    const hasNdbInData = timecard.days && timecard.days.some(x => x.ndbIn);
    const hasNdmInData = timecard.days && timecard.days.some(x => x.ndmIn);
    const hasNdmOutData = timecard.days && timecard.days.some(x => x.ndmOut);
    const hasLastMan1InData =
      timecard.days && timecard.days.some(x => x.lastMan1In);

    const paidHourHeaders = getPaidHourHeaders(timecard);
    const region = localStorage.getItem('region');
    const isCity = timecard.days.some(s => s.city);

    const headers = [
      {
        columnId: 'date',
        label: 'Date',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'locationType.name',
        label: 'Loc',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'dayType',
        label: 'Day Type',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'onProduction',
        label: 'On Prod',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'startTravel',
        label: 'Start Travel',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'call',
        label: 'Call',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'ndbIn',
        label: 'NDB In',
        compact: false,
        sortable: false,
        align: 'center',
        hidden: !hasNdbInData,
      },
      {
        columnId: 'meal1Out',
        label: 'Out1',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'meal1In',
        label: 'In1',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'lastMan1In',
        label: 'Last Man In',
        compact: false,
        sortable: false,
        align: 'center',
        hidden: !hasLastMan1InData,
      },
      {
        columnId: 'ndmOut',
        label: 'NDM Out',
        compact: false,
        sortable: false,
        align: 'center',
        hidden: !hasNdmOutData,
      },
      {
        columnId: 'ndmIn',
        label: 'NDM In',
        compact: false,
        sortable: false,
        align: 'center',
        hidden: !hasNdmInData,
      },
      {
        columnId: 'meal2Out',
        label: 'Out2',
        compact: false,
        sortable: false,
        hidden: meals <= 1,
        align: 'center',
      },
      {
        columnId: 'meal2In',
        label: 'In2',
        compact: false,
        sortable: false,
        hidden: meals <= 1,
        align: 'center',
      },
      {
        columnId: 'meal3Out',
        label: 'Out3',
        compact: false,
        sortable: false,
        hidden: meals <= 2,
        align: 'center',
      },
      {
        columnId: 'meal3In',
        label: 'In3',
        compact: false,
        sortable: false,
        hidden: meals <= 2,
        align: 'center',
      },
      {
        columnId: 'wrap',
        label: 'Wrap',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'travelEnd',
        label: 'Travel End',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'hours',
        label: 'Hours',
        compact: false,
        sortable: false,
        align: 'center',
      },
      ...paidHourHeaders,
      {
        columnId: 'freeField1',
        label: 'FF1',
        compact: false,
        sortable: false,
        hidden: payCodeFF1 < 1,
        align: 'center',
      },
      {
        columnId: 'freeField2',
        label: 'FF2',
        compact: false,
        sortable: false,
        hidden: payCodeFF2 < 1,
        align: 'center',
      },
      {
        columnId: 'freeField3',
        label: 'FF3',
        compact: false,
        sortable: false,
        hidden: payCodeFF3 < 1,
        align: 'center',
      },
      {
        columnId: 'freeField4',
        label: 'FF4',
        compact: false,
        sortable: false,
        hidden: payCodeFF4 < 1,
        align: 'center',
      },
      {
        columnId: 'accountCode',
        label: 'Account',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'country.name',
        label: 'Country',
        compact: false,
        sortable: false,
        hidden: countryCount < 1,
        align: 'center',
      },
      {
        columnId: 'state.name',
        label: isRegionCanada(region) ? 'Prov' : 'State',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'city.name',
        label: 'City',
        compact: false,
        sortable: false,
        align: 'center',
        hidden: isRegionCanada(region) && !isCity,
      },
    ];

    return (
      <TableList headers={headers} isAlternateRowColor={false}>
        {!!timecard.days && timecard.days.length > 0 ? (
          _.map(timecard.days, day => {
            if (day.htgDayTypeId) {
              const dayType = day.dayType;

              const paidHours = _.reduce(
                day.paidHours,
                (memo, paidHour) => {
                  memo[paidHour.name] = paidHour.value;
                  return memo;
                },
                {},
              );

              return (
                <TableRow key={day.id}>
                  {_.map(headers, (header, index) => {
                    if (header.hidden) return null;
                    if (
                      isRegionCanada(region) &&
                      header.columnId === 'locationType.name'
                    ) {
                      return null;
                    }
                    return (
                      <TableCell key={index} className={classes.tableCell}>
                        {(() => {
                          if (header.paidHours) {
                            return twoDecimalsNoRounding(
                              _.get(paidHours, header.columnId, '--') || '--',
                            );
                          }
                          switch (header.columnId) {
                            case 'date':
                              return formatDate(day.date, 'dd MM DD');
                            case 'dayType':
                              return dayType.name;
                            case 'onProduction':
                              return (
                                <Typography size="18">
                                  {day.onProduction ? (
                                    <CheckIcon />
                                  ) : (
                                    <SquareIcon />
                                  )}
                                </Typography>
                              );
                            case 'hours':
                              return twoDecimalsNoRounding(
                                calculateDayHours(day),
                              );

                            default:
                              return _.get(day, header.columnId, '--');
                          }
                        })()}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            }
          })
        ) : (
          <TableRow>
            <TableCell />
          </TableRow>
        )}
      </TableList>
    );
  }

  function renderPayCodes() {
    const region = localStorage.getItem('region');
    const isCity = timecard.brokenDetails.some(s => s.city);

    const headers = [
      {
        columnId: 'payCode',
        label: 'Pay Code',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'hours',
        label: 'Hours',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'prem',
        label: 'Prem',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'rate',
        label: 'Rate',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'factor',
        label: 'Factor',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'amount',
        label: 'Amount',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'accountCode',
        label: 'Account',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'freeField1',
        label: 'FF1',
        compact: false,
        sortable: false,
        align: 'center',
        hidden: payCodeFF1 < 1,
      },
      {
        columnId: 'freeField2',
        label: 'FF2',
        compact: false,
        sortable: false,
        align: 'center',
        hidden: payCodeFF2 < 1,
      },
      {
        columnId: 'freeField3',
        label: 'FF3',
        compact: false,
        sortable: false,
        align: 'center',
        hidden: payCodeFF3 < 1,
      },
      {
        columnId: 'freeField4',
        label: 'FF4',
        compact: false,
        sortable: false,
        align: 'center',
        hidden: payCodeFF4 < 1,
      },
      {
        columnId: 'country',
        label: 'Country',
        compact: false,
        sortable: false,
        hidden: countryCount < 1,
        align: 'center',
      },
      {
        columnId: 'state',
        label: isRegionCanada(region) ? 'Prov' : 'State',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'city',
        label: 'City',
        compact: false,
        sortable: false,
        align: 'center',
        hidden: isRegionCanada(region) && !isCity,
      },
      {
        columnId: 'occupationCodeId',
        label: 'Occ Code',
        compact: false,
        sortable: false,
        align: 'center',
      },
    ];

    let haveShownFringeTip = false;

    const checkIsLastLaborRow = index => {
      const row = timecard.brokenDetails[index];
      const nextRow = timecard.brokenDetails[index + 1];

      if (
        row.isAllowance === false &&
        nextRow &&
        (nextRow.isAllowance === true || nextRow.isAllowance === null)
      ) {
        return true;
      }

      return false;
    };

    return (
      <TableList headers={headers} isAlternateRowColor={false}>
        {!!timecard.brokenDetails && timecard.brokenDetails.length > 0 ? (
          _.map(timecard.brokenDetails, (detail, index) => {
            const isLastLaborRow = checkIsLastLaborRow(index);
            return (
              <TableRow key={index}>
                {_.map(headers, (header, index) => {
                  if (header.hidden) return null;
                  const showFringeTip =
                    showFringeInfo &&
                    !haveShownFringeTip &&
                    isLastLaborRow &&
                    header.columnId === 'amount';

                  if (showFringeTip) haveShownFringeTip = true;

                  return (
                    <TableCell key={index} className={classes.tableCell}>
                      {(() => {
                        if (
                          header.columnId.indexOf('freeField') >= 0 ||
                          header.columnId === 'payCode' ||
                          header.columnId === 'accountCode' ||
                          header.columnId === 'occupationCodeId' ||
                          header.columnId === 'dealMemoId' ||
                          header.columnId === 'rate'
                        ) {
                          return _.get(detail, header.columnId, '--') || '--';
                        } else {
                          return (
                            twoDecimalsNoRounding(
                              _.get(detail, header.columnId, '--'),
                            ) || '--'
                          );
                        }
                      })()}
                      {showFringeTip && (
                        <FringeTip fringeData={timecard?.estdata} />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell />
          </TableRow>
        )}
      </TableList>
    );
  }

  function renderAllowances() {
    const headers = [
      {
        columnId: 'reimbursementType',
        label: 'Reimbursement Type',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'amount',
        label: 'Amount',
        compact: false,
        sortable: false,
        align: 'center',
      },
      {
        columnId: 'supportingDocument"',
        label: 'Supporting Document',
        compact: false,
        sortable: false,
        align: 'center',
      },
    ];

    return (
      <TableList headers={headers} isAlternateRowColor={false}>
        {!!timecard.allowances && timecard.allowances.length > 0 ? (
          _.map(timecard.allowances, (allowance, index) => {
            return (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}>
                  {allowance?.allowanceType?.name}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {twoDecimalsNoRounding(allowance.amount)}
                </TableCell>
                <TableCell
                  style={{ color: 'blue' }}
                  className={classes.tableCell}
                >
                  <Typography>{allowance.filename}</Typography>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell />
          </TableRow>
        )}
      </TableList>
    );
  }

  return (
    <Paper className={classes.root}>
      {renderDays()}
      {renderPayCodes()}
      {renderAllowances()}
    </Paper>
  );
}
