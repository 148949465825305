import { useMediaQuery, useTheme } from '@mui/material';
import { POSSIBLE_MOBILE_SIZES } from 'components/Shared/constants';

/**
 * HOC that applies `isMobile` to wrapped component
 * Should only be used on class components that do not have access to hooks
 * otherwise use `useIsMobile` hook
 */

const withIsMobile = (size = 'md') => {
  if (!POSSIBLE_MOBILE_SIZES.includes(size)) {
    throw new Error(
      `withIsMobile: size must be one of ${POSSIBLE_MOBILE_SIZES.join(', ')}`,
    );
  }
  return BaseComponent => props => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(size));

    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    if (props.isMobile) {
      console.warn(
        BaseComponent.name || BaseComponent.displayName,
        'withIsMobile: props.isMobile is already defined and will be overridden',
      );
    }

    return <BaseComponent {...props} isMobile={isMobile} />;
  };
};

export default withIsMobile;
