import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader } from 'components/Nav';
import StickyHeader from 'components/Nav/StickyHeader';
import { connect } from 'react-redux';
import { LinearProgress } from '@mui/material';
import FadeInScroll from 'components/Shared/FadeInScroll';

import { fetch, fetchProjectSearch } from 'actions/project';
import { currentUser } from 'selectors/session';
import { getLoadingList } from 'selectors/project';

const mapStateToProps = state => ({
  activeUser: currentUser(state),
  loadingList: getLoadingList(state),
});

const mapDispatch = dispatch => ({
  onApplyFiltering: search => {
    if (search.length >= 3) dispatch(fetchProjectSearch({ search }));
    else if (search.length === 0) {
      dispatch(fetch());
    }
  },
});

class ProjectHeader extends React.Component {
  render() {
    const { onApplyFiltering, activeUser, loadingList } = this.props;

    return (
      <>
        <PageHeader
          title="Projects"
          searchBarPlaceHolder="Search Projects by ID, Name, Production Company or Coordinator Email"
          showSearch
          autoFocus
          onApplyFiltering={onApplyFiltering}
          activeUser={activeUser}
        />
        {loadingList && <LinearProgress />}

        <FadeInScroll customThreshold={260}>
          <StickyHeader
            title="Projects"
            searchBarPlaceHolder="Search Projects by ID, Name, Production Company or Coordinator Email"
            showSearch
            {...this.props}
          />
        </FadeInScroll>
      </>
    );
  }
}

ProjectHeader.propTypes = {
  onApplyFiltering: PropTypes.func.isRequired,
  activeUser: PropTypes.object.isRequired,
  loadingList: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatch)(ProjectHeader);
