/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

//constants
import { formatDateUTC } from 'utils/formatDate';
import { Checkbox } from '@mui/material';
import { TIMECARD_READY_FOR_ME } from 'components/Shared/constants';
import { DH, UPM, PA } from 'components/props/profiles';

export const STYLING = {
  cellPadding: '0px 12px 0px 12px',
};

export const INITIAL_PAGE_SIZE = 100;

export const EMPTY_FILTERS = {
  employee: [],
  weekEndingDate: [],
  status: [],
  department: [],
  invoice: [],
  union: [],
  accountCodeHeader: [],
  batch: [],
};

// Currently almost same as EMPTY filters, but Ready for Review is selected
export const INITIAL_FILTERS = {
  employee: [],
  weekEndingDate: [],
  status: [
    {
      label: 'Ready for me',
      value: 'ready_for_me',
      selected: true,
      index: 3,
    },
  ],
  department: [],
  invoice: [],
  union: [],
  accountCodeHeader: [],
  batch: [],
};

export const DEFAULT_HIDDEN_COLUMNS = [
  'grossTotal',
  'workHours',
  'paidHours',
  'lastModified',
];

export const DEFAULT_SORT = [
  { id: 'weekEndingDate', order: 'desc' },
  { id: 'department', order: 'asc' },
  { id: 'union', order: 'asc' },
  { id: 'occupation', order: 'asc' },
  { id: 'employee', order: 'asc' },
];

export const DEFAULT_STATUS_SORT = [
  'Draft',
  'Incomplete',
  'Rejected',
  'Ready for employee',
  'Ready for dept head',
  'Ready for me',
  'Ready for payroll acct',
  'Ready for approver 1',
  'Ready for approver 2',
  'Ready for approver 3',
  'Submitted to C&C',
  'Processing',
  'Paid',
];

export const TABLE_COLUMNS = [
  {
    id: 'selection',
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div>
        <Checkbox
          color="primary"
          {...getToggleAllRowsSelectedProps()}
          style={{ padding: '1px', zIndex: 10 }}
        />
      </div>
    ),

    Cell: ({ row }) => (
      <div>
        <Checkbox
          color="primary"
          {...row.getToggleRowSelectedProps()}
          style={{ padding: '1px' }}
        />
      </div>
    ),
  },
  {
    accessor: 'emergencyType',
    id: 'emergencyType',
    Header: '',
  },
  {
    accessor: 'weekEndingDate',
    id: 'weekEndingDate',
    Header: 'W/E',
  },
  {
    accessor: 'invoice',
    id: 'invoice',
    Header: 'Invoice Id',
  },
  {
    accessor: 'invoiceChargeDescription',
    id: 'invoiceChargeDescription',
    Header: 'Invoice Desc',
  },
  {
    accessor: 'batchWorksightHtgId',
    id: 'batchWorksightHtgId',
    Header: 'Batch Id',
  },
  {
    accessor: 'batchName',
    id: 'batchName',
    Header: 'Batch Name',
  },
  {
    accessor: 'account',
    id: 'account',
    Header: 'Account',
  },
  {
    accessor: 'employee',
    id: 'employee',
    Header: 'Employee',
  },
  {
    accessor: 'occupation',
    id: 'occupation',
    Header: 'Occupation',
  },
  {
    accessor: 'department',
    id: 'department',
    Header: 'Department',
  },
  {
    accessor: 'status',
    id: 'status',
    Header: 'Status',
  },
  {
    accessor: 'currentApprovers',
    id: 'currentApprovers',
    Header: 'Current Approvers',
  },
  {
    accessor: 'workedDaysDetails',
    id: 'daysEntered',
    Header: 'Days Entered',
  },
  {
    accessor: 'allowances',
    id: 'allowances',
    Header: 'Allowances',
  },
  {
    accessor: 'laborTotal',
    id: 'laborTotal',
    Header: 'Labor Total',
  },
  {
    accessor: 'grossTotal',
    id: 'grossTotal',
    Header: 'Gross Total',
  },
  {
    accessor: 'workHours',
    id: 'workHours',
    Header: 'Work Hours',
  },
  {
    accessor: 'paidHours',
    id: 'paidHours',
    Header: 'Paid Hours',
  },
  {
    accessor: 'lastModified',
    id: 'lastModified',
    Header: 'Last Modified',
  },
  {
    accessor: 'comments',
    id: 'comments',
    Header: '',
  },
];

/**
     * Handle Click on react-table header to change single sort
     * Used in both Bulk Edit and SearchTimecards
     * @param {object} column - react-table column obj
     * @param {func} setSortBy - callback to set the sort takes array
     * @param {array} defaultSort - default sort order. format each item: {id: string, desc: bool }

     */

/**
 * Create arguments for search filters
 * The only difference is the filter name
 * @params - All the arguments needed for filters and
 * @returns {func} create args for given filter name.
 */
export const makeFilterArgFactory = args => {
  const { fetchFilter, api, debug, globalFilters, projectId } = args;

  const makeFilterArgs = filterName => {
    const filters = globalFilters;
    const params = { filters, projectId, filterName };
    return [fetchFilter, api, debug, params];
  };

  return makeFilterArgs;
};

//used in DTS + wtcDrawer as well
export const setVisibleSelected = ({ fullList, visibleList, newValue }) => {
  fullList.forEach(option => {
    const isVisible = !!visibleList.find(
      visible => visible.index === option.index,
    );

    if (isVisible) option.selected = newValue;
  });
};

//input should be YYYY-MM-DD - using UTC to prevent TZ issues
export const searchDateFormat = str => formatDateUTC(str, 'MM-DD-YYYY');

export const makeDaysEntered = ({ value, errorClass }) => {
  const dayArray = [];

  if (value) {
    let prePendComma = false;
    for (const day in value) {
      if (Object.hasOwnProperty.call(value, day)) {
        const validDay = !!(
          value &&
          value[day] &&
          value[day].hasValidTimeEntry
        );
        const colorClass = validDay ? '' : errorClass;

        const text = `${day}`;

        const dayElm = (
          <span key={day}>
            {prePendComma && ','}
            <span className={colorClass}>{text}</span>
          </span>
        );
        prePendComma = true;
        dayArray.push(dayElm);
      }
    }
  }
  return dayArray;
};
export const makeDaysEnteredTooltip = ({ value }) => {
  const dayArray = [];

  if (value) {
    for (const day in value) {
      const timeElm = <p key={day}>{day + ':  ' + value[day].workedHours}</p>;
      dayArray.push(timeElm);
    }
  }
  return dayArray;
};

export const canTimecardBeSelected = (role, status) => {
  if (role === UPM || role === PA) {
    return true;
  }

  const checkBoxStatus = ['Draft'];
  if (role === DH) {
    checkBoxStatus.push(TIMECARD_READY_FOR_ME);
  }

  status = status && status.toLowerCase();

  checkBoxStatus.forEach((s, i, a) => {
    a[i] = a[i] && a[i].toLowerCase();
  });
  return checkBoxStatus.includes(status);
};

export const WEEK_ENDING_TO_PARAM = 'weekEndingTo';
export const BATCH_PARAM = 'batch';

export const getWeekEndingsTo = date => {
  const inputDate = moment(date, 'YYYY-MM-DD');
  if (!inputDate.isValid()) {
    throw new Error('Invalid date format. Please use YYYY-MM-DD.');
  }

  const startOfYear = inputDate.clone().startOf('year').subtract(1, 'year');
  const weekEndings = [];

  let currentDay = startOfYear.clone().day(6); // First Saturday of the year

  // Ensure we only get future Saturdays after Jan 1 if Jan 1 isn't Saturday
  if (currentDay.isBefore(startOfYear)) {
    currentDay.add(7, 'days');
  }

  while (currentDay.isSameOrBefore(inputDate)) {
    weekEndings.push(currentDay.format('YYYY-MM-DD'));
    currentDay.add(7, 'days');
  }

  return weekEndings;
};
