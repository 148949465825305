/* eslint-disable react/prop-types */
/* eslint-disable mui-unused-classes/unused-classes */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { emphasize } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Chip,
  MenuItem,
  NoSsr,
  Typography,
  Paper,
  FormHelperText,
  FormControl,
  Tooltip,
  useTheme,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from 'components/Shared/redux/TextField';
import debounce from 'lodash/debounce';

// This is specific to Material UI 4.0.x,
// In case if we update MUI in future, please refer following link.
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/autocomplete/IntegrationReactSelect.js

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 40,
  },
  wrapRoot: {
    flexGrow: 1,
    height: 'auto',
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'visible',
  },
  wrapValueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'middle',
    overflow: 'visible',
    maxWidth: 360,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light'
        ? theme.palette.gray['+6']
        : theme.palette.gray['-3'],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 'inherit',
    lineHeight: 1,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 14,
  },
  paper: {
    position: 'absolute',
    zIndex: 1280,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    width: 'fit-content',
  },
  divider: {
    height: theme.spacing(2),
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main}10 !important`,
    color: theme.palette.primary.main,
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

const InputComp = React.forwardRef((props, ref) => {
  return <div ref={ref} {...props} />;
});

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent: InputComp,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  if (props.data && props.data.email) {
    //ensure list is wide enough to properly display very long email addresses in tooltip
    const emailLen = props.data?.email?.length || 0;
    const optionWidth = Math.min(emailLen * 7, 300);
    return (
      <Tooltip
        title={props.data.email}
        arrow
        PopperProps={{ disablePortal: true }}
        enterDelay={1000}
        enterNextDelay={850}
      >
        <MenuItem
          ref={props.innerRef}
          selected={props.isFocused}
          component="div"
          classes={{
            selected: props.selectProps.classes.selected,
          }}
          style={{
            fontWeight: props.isSelected ? 500 : 400,
          }}
          {...props.innerProps}
        >
          <div style={{ minWidth: optionWidth }}>{props.children}</div>
        </MenuItem>
      </Tooltip>
    );
  }

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      classes={{
        selected: props.selectProps.classes.selected,
      }}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  const { children, data } = props;
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {data && !children ? data.name || null : children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  const { wrap, classes } = props.selectProps;
  return (
    <div
      className={!wrap ? classes.valueContainer : classes.wrapValueContainer}
    >
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

export default function IntegrationReactSelect(props) {
  // Handler from Parent

  const classes = useStyles();
  const {
    disabled = false,
    inputName,
    label,
    multiSelect = false,
    options = [],
    placeholder = 'Select a value...',
    error,
    helperText,
    isDefaultSelected = false,
    required,
    filterOptions,
    isClearable = true,
    defaultSelectedValue,
    async = false,
    wrap = false,
    loadOptions,
    onMenuOpen = () => {},
    onMenuClose = () => {},
    value,
    onChange,
    objectSelect,
    showLoadingMsg = false, //show 'loading...' instead of 'no options' initially if list is empty
  } = props;

  const theme = useTheme();

  const [isLoading, setIsLoading] = React.useState(false);
  const loadingRef = React.useRef(null);

  const enableLoadingMsg = () => {
    setIsLoading(true);
    clearTimeout(loadingRef.current);
    loadingRef.current = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const onMenuOpenWrapper = () => {
    onMenuOpen();
    if (showLoadingMsg && options.length === 0) {
      enableLoadingMsg();
    }
  };

  React.useEffect(() => {
    if (options.length > 0 && showLoadingMsg) {
      clearTimeout(loadingRef.current);
      setIsLoading(false);
    }
    return () => {
      clearTimeout(loadingRef.current);
    };
  }, [showLoadingMsg, options.length]);

  function onSelect(value) {
    if (onChange) {
      onChange(value, multiSelect, objectSelect);
    }
  }

  const inputId = 'auto-complete-for-' + inputName;
  let defaultValue = multiSelect ? [] : null;
  if (value) {
    if (multiSelect) {
      defaultValue = (value || []).map(val =>
        options.find(option => option.value === val),
      );
    } else if (objectSelect) {
      defaultValue = value;
    } else {
      defaultValue = options.find(option => option.value === value);
    }
  } else if (isDefaultSelected) {
    if (defaultSelectedValue) {
      defaultValue = options.find(
        option => option.value === defaultSelectedValue,
      );
    } else if (options.length === 1) {
      defaultValue = multiSelect ? options : options[0];
    }
  }

  const onInputChange =
    async && loadOptions
      ? debounce(
          newValue => {
            if (showLoadingMsg) enableLoadingMsg();
            loadOptions(newValue);
          },
          300,
          {
            leading: true,
            trailing: true,
            maxWait: 500,
          },
        )
      : () => {};

  const selectStyle = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    input: base => ({
      ...base,
      color: 'inherit',
      '& input': {
        font: 'inherit',
      },
    }),
    menuList: base => ({
      ...base,
      backgroundColor: theme.palette.background.paper,
      minWidth: 85,
      scrollbarWidth: 'thin',
      'div:hover': {
        backgroundColor: `${theme.palette.primary.main}10 !important`,
        color: theme.palette.primary.main,
      },
    }),
  };

  return (
    <div className={!wrap ? classes.root : classes.wrapRoot}>
      <NoSsr>
        <FormControl fullWidth error={error} required={required}>
          <Select
            classes={classes}
            inputId={inputId}
            wrap={wrap}
            TextFieldProps={{
              label,
              InputLabelProps: {
                htmlFor: inputId,
                shrink: true,
                disabled: disabled,
              },
              placeholder,
              disabled: disabled,
              error: error,
            }}
            isDisabled={disabled}
            isClearable={isClearable}
            isSearchable
            controlShouldRenderValue
            menuPlacement="auto"
            placeholder={placeholder}
            options={options}
            value={multiSelect ? [...defaultValue] : defaultValue}
            components={components}
            onChange={onSelect}
            isMulti={multiSelect || false}
            filterOption={filterOptions}
            styles={selectStyle}
            onInputChange={onInputChange}
            onMenuOpen={onMenuOpenWrapper}
            onMenuClose={onMenuClose}
            isLoading={isLoading}
            // menuPortalTarget={document.body} //causes reactWarning
            // menuIsOpen={true} //open by default for debugging
          />
          {error && (
            <FormHelperText id={`${inputId}-error-text`}>
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      </NoSsr>
    </div>
  );
}
