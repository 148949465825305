import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import withRouter from 'decorators/withRouter';
import {
  reduxForm,
  change,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form';
import { compose } from 'utils/helperFunctions';
import { push } from 'redux-first-history';

import { LinearProgress, Button } from '@mui/material';
// utils
import moment from 'utils/moment';
import { timecardValidate as validate } from 'utils/weekUtils';

// actions
import {
  fetchStatesV1,
  fetchCitiesV1,
  fetchSubdivisionsV1,
  storeStatesV1,
  storeCitiesV1,
} from 'actions/location';
import { fetchPayrollSettings } from 'actions/settings';
import { fetchPermissions } from 'actions/userInfo';
import {
  fetchSiblings,
  fetchProjectDepartment,
  fetchProjectUsers,
} from 'actions/project';

import {
  downloadSupportingDocument,
  fetchTeamTimecard,
  initTimecard,
  removeEmployee,
  saveTeamAllowance,
  setFromURL,
  submitTeamTimecard,
  selectAllowanceType,
  selectAllowanceAmount,
  selectAllowanceCombineCheckCode,
  uploadAllowanceDocument,
  resetAllowanceInput,
  teamTimecardUnmountCheck,
} from 'actions/timecards';
import * as actions from 'actions/timecards';
import { fetch as fetchProjectAllowances } from 'actions/projectAllowances';
import { show as showModal } from 'actions/modalDialog';
import { showAlert } from 'actions/alert';

// selectors
import { currentUser } from 'selectors/session';
import { getPermissions } from 'selectors/userInfo';
import { getStateOptions, getCityOptions } from 'selectors/wtc';
import {
  getProjectDetails,
  getDepartmentById,
  getRegion,
} from 'selectors/project';
import { getSettings, getProjectPayrollSettings } from 'selectors/settings';
import {
  getDayTypes,
  getEpisodes,
  getWorkLocations,
  isAllowanceProcessing,
} from 'selectors/timecard/common';
import {
  getCrewTimecard,
  getCrewMembers,
  isTeamTimecardProcessing,
  isLoading as getLoading,
  getTeamTimecardInitialValues,
  getTeamAllowances,
} from 'selectors/teamTimecard';
import { getProjectAllowances } from 'selectors/projectAllowances';
import { getAllowanceInput } from 'selectors/timecard/form';

// components
import Timecard from 'components/Employees/Timecards/Timecard';
import CrewMembers from 'components/Employees/CrewTimecard/CrewMembers';
import {
  statusByRole,
  TIMECARD_EDITABLE_OPTIONS,
} from 'components/Shared/constants';
import ConfirmTakeMeBack from 'containers/Employees/Timecards/Modals/ConfirmTakeMeBack';
import DeleteTimecardAllowance from 'containers/Employees/Timecards/Modals/DeleteTimecardAllowance';
import EmergencyTimecard from './Modals/EmergencyTimecard';

// utils
import { getEditableFields, isFieldEnabled } from 'utils/weekUtils';
import { getCrewPartialDays } from 'containers/Employees/Reviews/Shared/timecardUtils';
import { useDidMount, useWillUnmount } from 'utils/customHooks';

const FORM_NAME = 'teamTimecard';

const mapState = (state, { match }) => {
  const { weekEnding, departmentId } = match.params;
  const activeUser = currentUser(state);
  const initialValues = getTeamTimecardInitialValues(state);
  return {
    activeUser,
    allowances: getTeamAllowances(state, FORM_NAME),
    allowanceInput: getAllowanceInput(state),
    dayTypes: getDayTypes(state),
    department: getDepartmentById(state, departmentId),
    departmentId,
    employees: getCrewMembers(state),
    episodes: getEpisodes(state),
    initialValues,
    isAllowanceProcessing: isAllowanceProcessing(state),
    isViewOnly: false,
    loading: getLoading(state),
    payrollSettings: getProjectPayrollSettings(state),
    permissions: getPermissions(state),
    project: getProjectDetails(state),
    projectSettings: getSettings(state),
    region: getRegion(state),
    savingTimecard: isTeamTimecardProcessing(state),
    stateOptions: getStateOptions(state),
    cityOptions: getCityOptions(state),
    teamTimecardId: 'fresh-0',
    timecard: getCrewTimecard(state),
    timecardAllowances: getProjectAllowances(state),
    weekEnding,
    workLocations: getWorkLocations(state),
    formDataCheck: getFormValues(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors('teamTimecard')(state),
  };
};

const mapDispatch = (dispatch, { match }) => {
  const { weekEnding, departmentId, id, projectId } = match.params;
  const redirectURL = `/projects/${projectId}/timecards/week-ending/${weekEnding}/department/${departmentId}`;
  return {
    onFetchData: () => {
      dispatch(initTimecard());
      dispatch(setFromURL({ fromURI: redirectURL }));
      dispatch(fetchPermissions());
      dispatch(fetchSiblings());
      dispatch(fetchTeamTimecard({ weekEnding, departmentId, id }));
      dispatch(fetchProjectDepartment({ projectId, departmentId }));
      dispatch(fetchPayrollSettings());
      dispatch(fetchProjectAllowances());
      dispatch(fetchProjectUsers({ departmentId: match.params.departmentId }));
    },
    onTakeMeBack: () => dispatch(actions.onTakeMeBack()),
    onRemoveEmployee: userId => {
      dispatch(removeEmployee({ id, userId, weekEnding }));
    },
    onSubmitTimecard: () => {
      dispatch(submitTeamTimecard({ weekEnding, departmentId, id, projectId }));
    },
    onSubmitEmergencyTimecard: () => {
      dispatch(showModal({ dialog: 'emergencyTimecard' }));
    },
    onDownloadDocument: (file, token) => {
      if (token) {
        dispatch(downloadSupportingDocument({ token, file }));
      }
    },
    onSaveAllowance: (index, allowance) => {
      dispatch(
        saveTeamAllowance({
          index,
          allowance,
        }),
      );
    },
    onSelectAllowanceType: ({
      index,
      timecardId,
      allowanceType,
      isTeamTimecard,
    }) => {
      if (Number.isInteger(index)) {
        //existing allowance
        dispatch(
          change(
            FORM_NAME,
            `allowances[${index}].htgAllowanceTypeId`,
            allowanceType.id,
          ),
        );
      } else {
        dispatch(
          selectAllowanceType({
            index,
            timecardId,
            allowanceType,
            isTeamTimecard,
          }),
        );
      }
    },
    onSelectAllowanceAmount: ({
      index,
      timecardId,
      amount,
      isTeamTimecard,
    }) => {
      if (Number.isInteger(index)) {
        //existing allowance
        dispatch(change(FORM_NAME, `allowances[${index}].amount`, amount));
      } else {
        dispatch(
          selectAllowanceAmount({ index, timecardId, amount, isTeamTimecard }),
        );
      }
    },
    onSelectAllowanceCombineCheckCode: ({
      index,
      timecardId,
      allowanceType,
      combineCheckCode,
      isTeamTimecard,
    }) => {
      if (Number.isInteger(index)) {
        //existing allowance
        dispatch(
          change(
            FORM_NAME,
            `allowances[${index}].combineCheckCode`,
            combineCheckCode,
          ),
        );
      } else {
        dispatch(
          selectAllowanceCombineCheckCode({
            index,
            timecardId,
            allowanceType,
            combineCheckCode,
            isTeamTimecard,
          }),
        );
      }
    },
    onUploadDocument: ({ index, timecardId, document, isTeamTimecard }) => {
      if (Number.isInteger(index)) {
        //existing allowance
        dispatch(change(FORM_NAME, `allowances[${index}].document`, document));
        dispatch(
          change(FORM_NAME, `allowances[${index}].filename`, document?.name),
        );
      } else {
        dispatch(
          uploadAllowanceDocument({
            index,
            timecardId,
            document,
            isTeamTimecard,
          }),
        );
      }
    },
    onResetAllowance: ({ index, timecardId, isTeamTimecard }) => {
      if (Number.isInteger(index)) {
        //existing allowance
        dispatch(change(FORM_NAME, `allowances[${index}]`, {}));
      } else {
        dispatch(
          resetAllowanceInput({
            index,
            timecardId,
            isTeamTimecard,
          }),
        );
      }
    },
    onShowDeleteAllowanceModal: () => {
      dispatch(showModal({ dialog: 'DeleteTimecardAllowance' }));
    },

    onReturnToCreate: () => {
      dispatch(push(redirectURL));
    },
    onShowAlert: params => dispatch(showAlert(params)),
    onFetchStates: args => dispatch(fetchStatesV1(args)),
    onFetchCities: args => dispatch(fetchCitiesV1(args)),
    onFetchSubdivisions: args => dispatch(fetchSubdivisionsV1(args)),
    onResetLoc: variant => {
      switch (variant) {
        case 'workState':
          dispatch(storeStatesV1({ states: [] }));
          break;
        case 'workCity':
          dispatch(storeCitiesV1({ cities: [] }));
          break;
        default:
          break;
      }
    },
    onUnmount: () => {
      dispatch(teamTimecardUnmountCheck());
    },
  };
};

const onSubmit = null;

const onSubmitSuccess = (_result, dispatch, props) => {
  dispatch(props.reset()); // reset the form so that it loads fine.
};

const TeamTimecard = props => {
  const {
    timecard,
    activeUser,
    isProcessing,
    weekEnding,
    loading,
    employees = [],
    departmentId,
    onFetchData,
    onTakeMeBack,
    onShowDeleteAllowanceModal,
    onReturnToCreate,
    handleSubmit,
    onRemoveEmployee,
    change,
    onSubmitEmergencyTimecard,
    onSubmitTimecard,
    formDataCheck,
    initialValues,
    onShowAlert,
    onUnmount,
    ...others
  } = props;

  const [allowanceInProgress, setAllowanceInProgress] = React.useState(false);
  const [deleteAllowanceIndex, setDeleteAllowanceIndex] = React.useState(null);
  const [areNotesEmpty, setAreNotesEmpty] = React.useState(true);
  const [notesEmpty, setNotesEmpty] = React.useState({});

  const setNoteEmpty = React.useCallback(
    (noteId, isEmpty) => {
      notesEmpty[noteId] = isEmpty;

      let areNotesEmpty = true;
      for (const note in notesEmpty) {
        if (notesEmpty.hasOwnProperty(note)) {
          const setNoteEmpty = notesEmpty[note];
          areNotesEmpty = areNotesEmpty && setNoteEmpty;
        }
      }
      setNotesEmpty(notesEmpty);
      setAreNotesEmpty(areNotesEmpty);
    },
    [notesEmpty],
  );

  function onDeleteAllowance(id, index) {
    setDeleteAllowanceIndex(index);
    setTimeout(() => onShowDeleteAllowanceModal(), 100);
  }

  useDidMount(() => {
    if (_.isEmpty(initialValues)) {
      onReturnToCreate();
    } else {
      onFetchData();
    }
  });

  useWillUnmount(() => {
    onUnmount();
  });

  const copyToAll = React.useCallback(
    referenceDate => {
      const days = timecard.days;
      const referenceDay = _.find(days, day =>
        moment(day.date).isSame(referenceDate),
      );
      _.map(days, (day, index) => {
        if (!day.isActive) return day;

        const newDay = _.cloneDeepWith(referenceDay, () => {
          return {
            ...referenceDay,
            date: day.date,
            id: day.id,
            splits: null,
            isActive: true,
          };
        });

        change(`days[${index}]`, newDay);
        return {
          ...newDay,
        };
      });
    },
    [change, timecard.days],
  );

  function actionButtons() {
    const buttons = [];
    const {
      submitting,
      isViewOnly,
      timecard,
      permissions,
      departmentId,
      dayTypes,
      valid,
      pristine,
      formDataCheck,
      isAllowanceProcessing,
    } = props;

    const hasAllowances =
      formDataCheck?.allowances && formDataCheck.allowances.length > 0;

    const hasValidDay =
      formDataCheck?.days &&
      formDataCheck.days.some(
        d => _.isEmpty(d.dayType) === false && d.isActive,
      );

    const isDayTypeEmpty =
      dayTypes && dayTypes.length === 1 && _.isEmpty(dayTypes[0]);

    const disabled =
      submitting ||
      !areNotesEmpty ||
      allowanceInProgress ||
      (dayTypes && dayTypes.length === 0) ||
      isDayTypeEmpty ||
      pristine ||
      !valid ||
      (!hasAllowances && !hasValidDay) ||
      isAllowanceProcessing;

    const canSubmitEmergencyTimecard =
      permissions?.departments?.[departmentId]?.canSubmitEmergencyTimecard;

    if (!isViewOnly && timecard.status === 'incomplete') {
      buttons.push(
        <Button
          variant="outlined"
          color="primary"
          key="submitEmergency"
          disabled={disabled || !canSubmitEmergencyTimecard}
          onClick={onSubmitEmergencyTimecard}
        >
          {'Submit Emergency Timecard'}
        </Button>,
        <Button
          variant="contained"
          color="primary"
          key="submit"
          disabled={disabled}
          onClick={onSubmitTimecard}
        >
          {'Submit to Employees'}
        </Button>,
      );
    }

    return buttons;
  }

  if (loading || _.isEmpty(activeUser)) {
    return <LinearProgress />;
  }

  const editableFields = getEditableFields(timecard.status, activeUser.role);
  const isAllowanceEnabled = isFieldEnabled(editableFields, 'allowances');
  const statusBadges = statusByRole(activeUser.role);

  const crewPartialDays = getCrewPartialDays(timecard);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CrewMembers
          employees={employees}
          statusBadges={statusBadges}
          onRemove={onRemoveEmployee}
          timecardStatus={timecard.status}
          crewPartialDays={crewPartialDays}
        />
        <Timecard
          onShowAlert={onShowAlert}
          timecard={timecard}
          isTeamTimecard
          allowanceEditable={isAllowanceEnabled}
          showNotes={false}
          buttons={actionButtons()}
          editable={TIMECARD_EDITABLE_OPTIONS}
          onCopyToAll={copyToAll}
          loading={isProcessing}
          onTakeMeBack={onTakeMeBack}
          activeUser={activeUser}
          onDeleteAllowance={onDeleteAllowance}
          weekEnding={weekEnding}
          setNoteEmpty={setNoteEmpty}
          setAllowanceInProgress={setAllowanceInProgress}
          crewPartialDays={crewPartialDays}
          //formally in ...others
          change={change}
          handleSubmit={handleSubmit}
          onFetchData={onFetchData}
          onTakeMeBackAction={onTakeMeBack}
          onRemoveEmployee={onRemoveEmployee}
          onSubmitTimecard={onSubmitTimecard}
          onSubmitEmergencyTimecard={onSubmitEmergencyTimecard}
          onDeleteTimecardAllowance={onShowDeleteAllowanceModal}
          onApproveTimecard={() => {}} //quite propType warning for now
          employees={employees}
          {...others}
        />
      </form>
      <ConfirmTakeMeBack hasSubmitAction onSubmit={onTakeMeBack} />
      <DeleteTimecardAllowance
        index={deleteAllowanceIndex}
        weekEnding={weekEnding}
        isTeam
      />
      <EmergencyTimecard
        id={'fresh-0'}
        weekEnding={weekEnding}
        departmentId={departmentId}
        isTeamTimecard={true}
      />
    </>
  );
};

//lazy loaded in src\components\Routes\projectCrewTimecardRoutes.js
export default compose(
  withRouter,
  connect(mapState, mapDispatch),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    validate,
    onSubmit,
    onSubmitSuccess,
  }),
)(TeamTimecard);
