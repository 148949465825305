import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'utils/helperFunctions';
import {
  Paper,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import withIsMobile from 'decorators/withIsMobile';

// utils
import { currentWorkWeek } from 'utils/weekUtils';
import { weekEndingFormat as format } from 'utils/formatDate';

// components
import { projectProps } from 'components/props/projects';
import { statesProps } from 'components/props/locations';
import { timecardProps } from 'components/props/timecard';
import { EMPLOYEE } from 'components/props/profiles';
import { departmentProps } from 'components/props/departments';
import LocationAndMeals from './LocationAndMeals';
import ReadOnlyField from '../../../Shared/Text/ReadOnlyField';

const style = ({ palette, breakpoints }) => {
  const tableCell = {
    border: 'none',
    color: palette.getDefaultColor(),
  };

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      padding: 15,
      color: palette.getDefaultColor(),
    },
    divider: {
      marginBottom: 10,
      marginTop: 10,
    },
    tableRow: {
      '&:last-child': {
        textAlign: 'right',
        alignItems: 'right',
        alignContent: 'right',
      },
      margin: 5,
    },
    tableCell,
    actionColumn: {
      ...tableCell,
      display: 'inline-flex',
    },
    descriptionColumn: {
      ...tableCell,
      width: '20%',
    },
    locationColumn: {
      ...tableCell,
      width: '55%',
    },
    accountCodes: {
      ...tableCell,
      width: '55%',
      paddingTop: 24,
    },
    autoCompleteCol: {
      ...tableCell,
      width: '20%',
      paddingRight: 14,
    },
    employeeInfo: {
      ...tableCell,
      display: 'flex',
    },
    viewDealMemos: {
      margin: '22px 20px 0px',
      cursor: 'pointer',
      color: palette.primary['+6'],
      fontSize: 14,
      fontWeight: 500,
      '& > span': {
        verticalAlign: 'middle',
      },
    },
    [breakpoints.down('sm')]: {
      autoCompleteCol: {
        marginTop: 25,
      },
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& > *': {
        width: '100%',
      },
    },
    autoComplete: {
      width: '40%',
    },
    panelAdjustments: {
      boxShadow: 'none',
      maxWidth: '95%',
    },
    linkLook: {
      color: palette.misc.link,
      textDecoration: 'underline',
      width: '40%',
      paddingLeft: 14,
    },
    employeeCodes: {
      textAlign: 'left',
      paddingLeft: 14,
      marginBottom: 10,
      '& span': {
        fontSize: 14,
        fontWeight: 500,
        color: palette.text.disabled,
      },
      '& h6': {
        fontSize: 14,
        color: palette.text.primary,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
    accountCodesSwitch: {
      textAlign: 'right',
      margin: '24px 16px',
      color: palette.text.primary,
    },
  };
};

class TimecardHeader extends React.Component {
  static propTypes = {
    buttons: PropTypes.node,
    classes: PropTypes.object.isRequired,
    department: departmentProps,
    hideDepartment: PropTypes.bool,
    index: PropTypes.number,
    loading: PropTypes.bool,
    project: projectProps.isRequired,
    role: PropTypes.string.isRequired,
    stateOptions: statesProps.isRequired,
    timecard: timecardProps.isRequired,
    isViewOnly: PropTypes.bool,
    showAccountCodes: PropTypes.bool,
    onToggleAccountCodes: PropTypes.func,
  };

  static defaultProps = {
    buttons: undefined,
    crew: false,
    hideDepartment: false,
    index: -1,
  };

  state = {
    showHideLabel: 'Show',
  };

  renderDepartmentSection(timecard, department) {
    const departmentName =
      (department && department.name) || timecard.departmentName;
    return (
      <Typography variant="subtitle2">
        {departmentName} {' | '} {format(timecard.endsOn)}
      </Typography>
    );
  }

  render() {
    const {
      classes,
      editableFields,
      department,
      role,
      timecard,
      project,
      isTeamTimecard = false,
      onToggleAccountCodes,
      showAccountCodes,
      stateOptions,
      cityOptions,
      caRegion,
      isMobile,
    } = this.props;

    const startsOn = format(timecard.startsOn);
    const workWeek = currentWorkWeek(project, startsOn);
    const onShow = panel => (event, newExpanded) => {
      this.setState({
        showHideLabel: newExpanded ? 'Hide' : 'Show',
      });
    };

    return (
      <Paper elevation={0} square className={classes.root}>
        {role !== EMPLOYEE && !isTeamTimecard && (
          <Table>
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.employeeInfo}>
                  <div>
                    <Typography variant="h6" color="inherit">
                      {this.props.index > 0 && `#${this.props.index} `}{' '}
                      {timecard.user && timecard.user.fullName}
                    </Typography>
                    {this.renderDepartmentSection(timecard, department)}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {isTeamTimecard && (
          <Table>
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <Typography variant="h6">
                    <b>{department && department.name}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {(role === EMPLOYEE || isTeamTimecard) && (
          <Table>
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  <Typography variant="h6" color="inherit">
                    {timecard && format(timecard.endsOn)} {' | '}{' '}
                    {`Production Week ${workWeek.starts[0]}-${workWeek.ends[0]}`}
                  </Typography>
                </TableCell>
                <TableCell className={classes.actionColumn} />
              </TableRow>
            </TableBody>
          </Table>
        )}
        <Divider className={classes.divider} />
        <Paper elevation={0}>
          <Grid container>
            <Grid item xs={12} sm={6} md={3} className={classes.employeeCodes}>
              {!isTeamTimecard && (
                <ReadOnlyField
                  title="Occupation Code"
                  value={
                    (timecard.dealMemo && timecard.dealMemo.jobDescription) ||
                    'Pending'
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.employeeCodes}>
              {!isTeamTimecard && (
                <ReadOnlyField
                  title="Union"
                  value={
                    (timecard.union && timecard.union.description) ||
                    (timecard.dealMemo &&
                      timecard.dealMemo.htgUnion &&
                      timecard.dealMemo.htgUnion.name)
                  }
                />
              )}
            </Grid>
            {!isMobile && (
              <React.Fragment>
                <Grid item md={6}>
                  <LocationAndMeals
                    caRegion={caRegion}
                    stateOptions={stateOptions}
                    cityOptions={cityOptions}
                    currentState={timecard.state}
                    editableFields={editableFields}
                    timecard={timecard}
                    {...this.props}
                  />
                </Grid>
                <Grid item md={12} className={classes.accountCodesSwitch}>
                  Account Codes
                  <Switch
                    color="primary"
                    onChange={onToggleAccountCodes}
                    checked={showAccountCodes}
                  />
                </Grid>
              </React.Fragment>
            )}
            {isMobile && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Accordion
                    className={classes.panelAdjustments}
                    onChange={onShow()}
                  >
                    <AccordionSummary className={classes.linkLook}>
                      {this.state.showHideLabel} Additional Work Info
                    </AccordionSummary>
                    <AccordionDetails>
                      <LocationAndMeals
                        caRegion={caRegion}
                        stateOptions={stateOptions}
                        cityOptions={cityOptions}
                        currentState={timecard.state}
                        editableFields={editableFields}
                        timecard={timecard}
                        {...this.props}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Paper>
      </Paper>
    );
  }
}

export default compose(withStyles(style), withIsMobile())(TimecardHeader);
