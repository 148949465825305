import _ from 'lodash';
import { setGlobalDevModeChecks } from 'reselect';

/**
 * Reselect has some development-only checks that help you avoid common mistakes.
 * but until we fix them, we'll disable them globally here.
 */
//https://reselect.js.org/api/development-only-stability-checks/
setGlobalDevModeChecks({
  identityFunctionCheck: 'never',
  inputStabilityCheck: 'never',
});

export const getFlagsReady = state => _.get(state, 'appReady.flagsReady');

export const getHideGlobalNav = state => _.get(state, 'appReady.hideGlobalNav');
