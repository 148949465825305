import { buildUrl } from '../api';

const URL_EMPLOYEES = '/projects/:projectId/employees';

const URL_DEALMEMO = '/projects/:projectId/employees/:employeeId/dealmemo';

const URL_ROUND_TO = '/projects/:projectId/employees/dealmemos/roundTo';
const URL_SCALE_RATES =
  '/projects/:projectId/employees/dealmemos/:dealMemoId/scales/:weekEndingDate/:payAtScale/:dbCode';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ clientV2 }) => ({
  //formally api.timecards.fetchEmployees
  async fetchEmployees({ projectId }) {
    const url = buildUrl(URL_EMPLOYEES, { projectId });
    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  //This is labeled projectId, but the actual value is the projectWorksightId GUID
  //formally api.wtc.dealMemos
  async dealMemos({ projectId, employeeId, startDate, endDate, withPayroll }) {
    let url = buildUrl(URL_DEALMEMO, { projectId, employeeId });

    if (startDate && endDate) {
      url += `/startDate/${startDate}/endDate/${endDate}`;
    }
    withPayroll ? (url += '/true') : (url += '/false');

    const rsp = await clientV2.get(url);
    return rsp.data;
  },

  async roundTo({ projectId, dealMemoIds }) {
    const URL = URL_ROUND_TO;
    const url = buildUrl(URL, { projectId });
    const rsp = await clientV2.post(url, dealMemoIds);
    return rsp.data;
  },
  async payAtScales({
    projectId,
    dealMemoId,
    weekEndingDate,
    payAtScale,
    dbCode,
  }) {
    const url = buildUrl(URL_SCALE_RATES, {
      projectId,
      dealMemoId,
      weekEndingDate,
      payAtScale,
      dbCode,
    });

    const rsp = await clientV2.get(url);

    return rsp.data;
  },
});
