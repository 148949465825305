// Planning to use it with both admin as well as normal user.
import createAction from '../../actions/createAction';

export const loading = createAction('searchInvoices/loading');

export const store = createAction('searchInvoices/store');
export const storeSort = createAction('searchInvoices/storeSort');

export const loadMore = createAction('searchInvoices/loadMore');

export const cleanup = createAction('searchInvoices/cleanup');
export const reset = createAction('searchInvoices/reset');
export const init = createAction('searchInvoices/init');

//filterDropdown actions
export const fetch = createAction('searchInvoices/fetch');

export const clearFilters = createAction('searchInvoices/clearFilters');

export const setInitialFilters = createAction(
  'searchInvoices/setInitialFilters',
);
export const setExpandFilters = createAction('searchInvoices/setExpandFilters');

export const setSearchFilter = createAction('searchInvoices/setSearchFilter');

export const onSelect = createAction('searchInvoices/onSelect');
export const onSelectAll = createAction('searchInvoices/onSelectAll');

export const storeFilterOptions = createAction(
  'searchInvoices/storeFilterOptions',
);
export const setRemovedSelectedOption = createAction(
  'searchInvoices/setRemovedSelectedOption',
);
export const setSelectedInvoice = createAction(
  'searchInvoices/setSelectedInvoice',
);
export const clearSelectedInvoice = createAction(
  'searchInvoices/clearSelectedInvoice',
);

//filter badges count
export const fetchFilterBadgesCount = createAction(
  'searchInvoices/fetchFilterBadgesCount',
);
