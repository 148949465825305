import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { withStyleSheet } from 'shared/theme';
import SearchBar from 'components/Shared/SearchBar';
import AppBar from './AppBar';
import Title from './Title';
import _ from 'lodash';

import FilterListIcon from '@mui/icons-material/FilterList';
import ProjectListFilter from './ProjectListFilter';

const style = ({ palette, breakpoints }) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    color: palette.getDefaultColor(),
  },
  navSection: {
    textAlign: 'right',
    [breakpoints.down('xs')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  toolBar: {
    minHeight: 48,
  },
  searchBar: {
    flexGrow: 1,
    borderBottom: '2px solid #fafafa', //hardCoded Colors
  },
  searchBarBorder: {
    borderBottom: '2px solid #3c9dd7', //hardCoded Colors
  },
  filterIcon: {
    height: 24,
    width: 24,
    border: `1px solid ${palette.primary.main}`,
    borderRadius: 2,
    marginTop: 24,
    cursor: 'pointer',
    color: palette.primary.main,
  },
  [breakpoints.down('md')]: {
    root: {
      '&:first-child': {
        paddingTop: 8,
      },
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  [breakpoints.down('sm')]: {
    appBar: {
      backgroundColor: palette.background.default,
      boxShadow: 'none',
      marginBottom: 0,
    },
    root: {
      background: palette.background.default,
    },
    toolBar: {
      paddingBottom: 8,
      background: palette.background.default,
    },
    searchBar: {
      background: palette.background.default,
      border: `1px solid ${palette.gray['+7']}`,
      marginTop: '12px',
      width: '80%',
    },
  },
});

const PageHeader = props => {
  const {
    classes,
    showSearch = false,
    searchBarPlaceHolder = 'Search...',
    title,
    navItems = [],
    autoFocus = false,
    activeUser,
    onApplyFiltering,
  } = props;

  const [focus, setFocus] = React.useState(false);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const inputRef = React.useRef(null);
  const theme = useTheme();
  const isSmMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isAdmin = activeUser?.role === 'admin';
  return (
    <AppBar alternate classes={{ root: classes.appBar }}>
      <Toolbar className={classes.toolBar}>
        {!isSmMobile && (
          <React.Fragment>
            <Grid
              container
              className={classes.root}
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Grid item sm={showSearch ? 2 : 4}>
                <Title title={title} />
              </Grid>
              {showSearch && (
                <Grid
                  item
                  xs={6}
                  sm={5}
                  md={6}
                  className={clsx(classes.searchBar, {
                    [classes.searchBarBorder]: focus,
                  })}
                >
                  <SearchBar
                    onFilter={_.debounce(
                      event => onApplyFiltering(event.target.value || ''),
                      200,
                    )}
                    placeholder={searchBarPlaceHolder}
                    onFieldBlur={() => setFocus(false)}
                    onFieldFocus={() => setFocus(true)}
                    autoFocus={autoFocus}
                  />
                </Grid>
              )}
              {isAdmin && title === 'Projects' && (
                <Grid item>
                  <FilterListIcon
                    ref={inputRef}
                    className={classes.filterIcon}
                    onClick={() => setIsFilterOpen(true)}
                  />
                  <ProjectListFilter
                    anchorElRef={inputRef}
                    isFilterOpen={isFilterOpen}
                    setCloseFilterPopup={() => setIsFilterOpen(false)}
                  />
                </Grid>
              )}
              <Grid item sm={showSearch ? 3 : 6} className={classes.navSection}>
                {navItems}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {isSmMobile && (
          <React.Fragment>
            <Grid
              container
              className={classes.root}
              alignItems="flex-start"
              direction="column"
              justifyContent="space-between"
            >
              <Grid item md={4} sm={12}>
                <Title title={title} />
              </Grid>
              {showSearch && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className={clsx(classes.searchBar, {
                    [classes.searchBarBorder]: focus,
                  })}
                >
                  <SearchBar
                    onFilter={_.debounce(
                      event => onApplyFiltering(event.target.value || ''),
                      200,
                    )}
                    placeholder={searchBarPlaceHolder}
                    onFieldBlur={() => setFocus(false)}
                    onFieldFocus={() => setFocus(true)}
                  />
                </Grid>
              )}
              <Grid
                container
                className={classes.root}
                alignItems="center"
                direction="column"
                justifyContent="space-between"
              >
                <Grid item sm={6} />
                <Grid item sm={12} className={classes.navSection}>
                  {navItems}
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
};

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  showSearch: PropTypes.bool,
  searchBarPlaceHolder: PropTypes.string,
  title: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.node),
  autoFocus: PropTypes.bool,
  activeUser: PropTypes.object,
  onApplyFiltering: PropTypes.func,
};

export default withStyleSheet('PageHeader', style)(PageHeader);
